import React, { Component } from "react";
import {
	Card,
	Button,
	Icon,
	Modal,
	Header,
	Form,
	Message,
} from "semantic-ui-react";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as mutation from "../../../GraphQL/mutations";
import { toast } from "react-toastify";

export default class Actions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			settingsOpen: false,
			Channel1: {
				SetPoint: null,
				AlarmHighSP: null,
				AlarmLowSP: null,
			},
			Channel2: {
				SetPoint: null,
				AlarmHighSP: null,
				AlarmLowSP: null,
			},
			channels: [],
		};
	}

	handleSettingsClose = () => this.setState({ settingsOpen: false });

	handleSettingsOpen = () => {
		console.log(this.props);
		const { Channel1, Channel2 } = this.props.data;
		this.setState({
			settingsOpen: true,
			Channel1: Channel1,
			Channel2: Channel2,
		});
	};

	onSetChange = (event, data) => {
		if (
			(!Number.isNaN(data.value) &&
				data.value >= data.minimum &&
				data.value <= data.maximum) ||
			data.value === "" ||
			data.value === "-"
		)
			this.setState({
				[data.channel]: {
					...this.state[data.channel],
					[data.name]: data.value,
				},
			});
	};

	onConfigChange = (event, data) => {
		if (data.value.length < 20) {
			this.setState({ [data.name]: data.value });
		}
	};

	onOrderChange = (event, data) => {
		if (
			(Number.isInteger(Number(data.value)) &&
				data.value > 0 &&
				data.value <= 50) ||
			data.value === ""
		) {
			this.setState({ [data.name]: data.value });
		}
	};

	handleChannelChange = (event, data) => {
		this.setState((state) => {
			const channels = state.channels.map((item, index) => {
				const newItem = { ...item };
				if (data.index === index) {
					newItem[data.name] = data.value;
				}
				return newItem;
			});
			return { channels };
		});
	};

	handleConfigOpen = () => {
		const { title, subtitle, order, channels } = this.props;
		this.setState({
			configOpen: true,
			title: title,
			subtitle: subtitle,
			order: order,
			channels: channels,
		});
	};

	handleConfigClose = () => this.setState({ configOpen: false });

	SuccessMessage = (message) => ({ closeToast }) => (
		<Message
			success
			icon="check"
			header="Success"
			content={message}
			onDismiss={closeToast}
		/>
	);

	ErrorMessage = (message) => ({ closeToast }) => (
		<Message
			error
			icon="warning circle"
			header="Error"
			content={message}
			onDismiss={closeToast}
		/>
	);

	render() {
		const { configOpen, title, subtitle, order, channels } = this.state;
		const { pk, sk, onUpdate } = this.props;

		const configUpdateDisabled = !(title && order && subtitle);

		return (
			<Card.Content extra>
				<Modal
					open={configOpen}
					onClose={this.handleConfigClose}
					size="mini"
					trigger={
						<Button
							floated="right"
							basic
							compact
							onClick={this.handleConfigOpen}
						>
							<Icon name="configure" />
							Configure
						</Button>
					}
					closeIcon
					closeOnDimmerClick={false}
					closeOnEscape={false}
				>
					<Header as="h4">
						<Icon name="configure" />
						<Header.Content>
							Configure
							<Header.Subheader>{sk}</Header.Subheader>
						</Header.Content>
					</Header>
					<Modal.Content>
						<Form>
							<Form.Input
								label="Title"
								value={title}
								name="title"
								onChange={this.onConfigChange}
							/>
							<Form.Input
								label="Subtitle"
								value={subtitle}
								name="subtitle"
								onChange={this.onConfigChange}
							/>
							<Form.Input
								label="Order"
								value={order}
								name="order"
								onChange={this.onOrderChange}
							/>
							<Connect mutation={graphqlOperation(mutation.UpdateDevice)}>
								{({ mutation }) => (
									<Button
										disabled={configUpdateDisabled}
										positive
										onClick={() => {
											const input = {
												name: sk,
												title: title,
												subtitle: subtitle,
												order: Number(order),
												channels: channels,
											};
											mutation({
												input: input,
												groupID: pk,
											})
												.then((response) => {
													console.log(response);
													const {
														name,
														title,
														subtitle,
														order,
														channels,
													} = response.data.updateDevice;
													onUpdate(name, title, subtitle, order, channels);
													toast(this.SuccessMessage("Config Updated"));
													this.handleConfigClose();
												})
												.catch((err) => {
													console.error(err);
													toast(this.ErrorMessage("Update Failed"));
													this.handleConfigClose();
												});
										}}
									>
										Update
									</Button>
								)}
							</Connect>
						</Form>
					</Modal.Content>
				</Modal>
			</Card.Content>
		);
	}
}
