import React from "react";
import { Statistic } from "semantic-ui-react";

export default function Channel(props) {
  const { color, name, unit, Value, Connected } = props;
  return (
    <Statistic color={color}>
      <Statistic.Label>{name + "(" + unit + ")"}</Statistic.Label>
      <Statistic.Value className={color !== "black" ? "Stat-alert" : null}>
        {Connected ? Value : "N/C"}
      </Statistic.Value>
    </Statistic>
  );
}
