import React, { Component } from "react";
import { Button, Modal, Icon, Form, Select } from "semantic-ui-react";

const options = [
  { key: "m", text: "Manager", value: 75 },
  { key: "s", text: "Supervisor", value: 50 },
  { key: "u", text: "User", value: 25 }
];

export default class AddUserPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      username: "",
      role: "",
      open: false
    };
  }

  handleChange(name, event, data) {
    this.setState({ [name]: data.value });
  }

  submit() {
    const { onCreate, groupID } = this.props;
    const input = {
      groupID: groupID,
      username: this.state.username,
      role: this.state.role
    };

    try {
      onCreate(input);
      this.setState({ open: false });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { username, role, open } = this.state;
    return (
      <Modal
        trigger={
          <Button basic onClick={() => this.setState({ open: true })}>
            <Icon name="add user" />
            Add User
          </Button>
        }
        open={open}
        onClose={() => {
          this.setState({ open: false });
        }}
        closeIcon
        closeOnDimmerClick={false}
        style={{ width: 320 }}
      >
        <Modal.Header>Add New User</Modal.Header>
        <Modal.Content as={Form}>
          <Form.Input
            label="Username"
            placeholder="Enter Username"
            value={username}
            onChange={(event, data) => {
              this.handleChange("username", event, data);
            }}
          />
          <Form.Field
            control={Select}
            label="Role"
            options={options}
            placeholder="Select Role"
            value={role}
            onChange={(event, data) => {
              this.handleChange("role", event, data);
            }}
          />
          <Form.Button
            positive
            onClick={this.submit}
            disabled={this.props.userList.includes(this.state.username)}
          >
            Add user
          </Form.Button>
        </Modal.Content>
      </Modal>
    );
  }
}
