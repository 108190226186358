import React from "react";
import { Card } from "semantic-ui-react";
import CardUpdatedOn from "./CardUpdatedOn";
export default function CardTitles(props) {
  const { sk, title, subtitle, updatedOn } = props;

  return (
    <React.Fragment>
      <Card.Content>
        <Card.Meta textAlign="right">{sk}</Card.Meta>
        <Card.Header>{title}</Card.Header>
        <Card.Meta>{subtitle}</Card.Meta>
        <CardUpdatedOn date={updatedOn} />
      </Card.Content>
    </React.Fragment>
  );
}
