import React from "react";
import { Responsive, Menu, Icon } from "semantic-ui-react";

export default function SelectedGroupMenu(props) {
  const {
    onMenuButtonClick,
    onBackClick,
    currentState,
    role,
    theme_dark
  } = props;

  return (
    <React.Fragment>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Menu widths={6} inverted={theme_dark}>
          <Menu.Item
            icon="tags"
            content="Devices"
            name="Devices"
            active={currentState === "Devices"}
            color="blue"
            onClick={onMenuButtonClick}
          />
          <Menu.Item
            icon="database"
            content="Data"
            name="Data"
            active={currentState === "Data"}
            color="orange"
            onClick={onMenuButtonClick}
            disabled={role < 50}
          />
          <Menu.Item
            icon="users"
            content="Users"
            name="Users"
            active={currentState === "Users"}
            color="teal"
            onClick={onMenuButtonClick}
          />
          <Menu.Item
            icon="settings"
            content="Group Settings"
            name="Group Settings"
            active={currentState === "Group Settings"}
            color="brown"
            onClick={onMenuButtonClick}
            disabled={role < 100}
          />
          <Menu.Item
            icon="plus"
            content="Add Device"
            name="Add Device"
            active={currentState === "Add Device"}
            color="green"
            onClick={onMenuButtonClick}
            disabled={role < 100}
          />
          <Menu.Item
            icon="left arrow"
            content="Back"
            name="Back"
            color="red"
            active
            onClick={onBackClick}
          />
        </Menu>
      </Responsive>
      <Responsive {...Responsive.onlyMobile}>
        <Menu widths={6} icon>
          <Menu.Item
            //content="Devices"
            name="Devices"
            active={currentState === "Devices"}
            color="blue"
            onClick={onMenuButtonClick}
          >
            <Icon name="tags" />
          </Menu.Item>
          <Menu.Item
            // content="Data"
            name="Data"
            active={currentState === "Data"}
            color="orange"
            onClick={onMenuButtonClick}
          >
            <Icon name="database" />
          </Menu.Item>
          <Menu.Item
            //content="Users"
            name="Users"
            active={currentState === "Users"}
            color="teal"
            onClick={onMenuButtonClick}
          >
            <Icon name="users" />
          </Menu.Item>
          <Menu.Item
            //content="Group Settings"
            name="Group Settings"
            active={currentState === "Group Settings"}
            color="brown"
            onClick={onMenuButtonClick}
          >
            <Icon name="settings" />
          </Menu.Item>
          <Menu.Item
            //content="Add Device"
            name="Add Device"
            active={currentState === "Add Device"}
            color="green"
            onClick={onMenuButtonClick}
          >
            <Icon name="plus" />
          </Menu.Item>
          <Menu.Item
            // content="Back"
            name="Back"
            color="red"
            active
            onClick={onBackClick}
          >
            <Icon name="arrow left" />
          </Menu.Item>
        </Menu>
      </Responsive>
    </React.Fragment>
  );
}
