import React from "react";
import { Table } from "semantic-ui-react";

export default function ReportTable(props) {
  const {  data } = props;
  console.log(data);
  return (
    <React.Fragment>
      <Table unstackable compact celled textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="2">Timestamp</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Humidity (%)</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">Temperature (°C)</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Process</Table.HeaderCell>
            <Table.HeaderCell>Fan Set Point</Table.HeaderCell>
            <Table.HeaderCell>Pump Set Point</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body
          children={data.map(item => (
            <Table.Row key={item.sk}>
              <Table.Cell>{item.sk}</Table.Cell>
              <Table.Cell>{item.payload.data.THP[0].RH}</Table.Cell>
              <Table.Cell>{item.payload.data.PID[0].SetPoint}</Table.Cell>
              <Table.Cell>{item.payload.data.PID[0].SetPoint}</Table.Cell>
              <Table.Cell>{item.payload.data.THP[0].TEMP}</Table.Cell>
            </Table.Row>
          ))}
        />
      </Table>
    </React.Fragment>
  );
}
