import React, { Component } from "react";
import "./App.css";

import Auth from "@aws-amplify/auth";
import { Authenticator, Greetings, SignIn } from "aws-amplify-react";
import AWS from "aws-sdk";

import AppWithAuth from "./Components/Auth/AppWithAuth";

import CustomSignIn from "./Components/Auth/SignIn";

import Amplify from "aws-amplify";

import awsmobile from "./aws-exports";

import { Analytics } from "aws-amplify";

Analytics.configure({ disabled: true });

Auth.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "ap-south-1:c2694af7-1733-4ed3-8d59-7b895a54b4a6",

    // REQUIRED - Amazon Cognito Region
    region: "ap-south-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-south-1_tEdJO5uRj",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "513jcp8dmfhb9mkq0414lld9ql",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: false,
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: ".libratherm.in",
    //   // OPTIONAL - Cookie path
    //   path: "/",
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true
    // },
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_SRP_AUTH"
  }
});

Amplify.configure(awsmobile);

const signUpConfig = {
  defaultCountryCode: 91,
  signUpFields: [
    {
      label: "Full Name",
      required: true,
      key: "name",
      displayOrder: 1,
      placeholder: "Full Name"
    },
    {
      label: "Company Name",
      required: false,
      key: "custom:companyname",
      displayOrder: 6,
      placeholder: "Company Name"
    }
  ]
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      theme_color_name: "blue"
    };
  }

  componentDidMount() {
    fetch("/manifest.json")
      .then(res => res.json())
      .then(res => {
        document.title = res.name;
        this.setState({ theme_color_name: res.theme_color_name });
      })
      .catch(err => console.log(err));
  }

  loadIoTPolicy = async authState => {
    if (authState === "signedIn") {
      const credentials = await Auth.currentCredentials();

      const iot = new AWS.Iot({
        region: "ap-south-1",
        credentials: Auth.essentialCredentials(credentials)
      });

      const policyName = "myIOTPolicy";
      const target = credentials._identityId;

      const { policies } = await iot.listAttachedPolicies({ target }).promise();

      if (!policies.find(policy => policy.policyName === policyName)) {
        try {
          await iot.attachPolicy({ policyName, target }).promise();
          console.log("Policy Attached");
        } catch (e) {
          console.error("Policy Error : ", e);
        }
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Authenticator
          authState="signIn"
          hide={[SignIn, Greetings]}
          signUpConfig={signUpConfig}
          onStateChange={authState => this.loadIoTPolicy(authState)}
        >
          <CustomSignIn theme_color_name={this.state.theme_color_name} />
          <AppWithAuth />
        </Authenticator>
      </React.Fragment>
    );
  }
}

export default App;
