import React from "react";
import { Table, Icon } from "semantic-ui-react";

export default function Cell(props) {
  const { Value, AlarmHigh, AlarmLow, Connected } = props;
  if (!Connected) {
    return (
      <Table.Cell warning>
        <Icon name="warning circle" />
        N/C
      </Table.Cell>
    );
  }
  if (Value.trim() === "OFF") {
    return <Table.Cell disabled>OFF</Table.Cell>;
  } else if (isNaN(Value)) {
    console.log(Value);
    return (
      <Table.Cell warning>
        <Icon name="warning" />
        {Value}
      </Table.Cell>
    );
  } else {
    return (
      <Table.Cell positive={AlarmLow} negative={AlarmHigh}>
        {Value}
      </Table.Cell>
    );
  }
}
