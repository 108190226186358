import React from "react";
import { Card, Statistic } from "semantic-ui-react";

export default function DualAIStats(props) {
  const { Channel1, Channel2, titles } = props;

  Channel1.color = Channel1.AlarmHigh
    ? "red"
    : Channel1.AlarmLow
    ? "blue"
    : "black";

  Channel2.color = Channel2.AlarmHigh
    ? "red"
    : Channel2.AlarmLow
    ? "blue"
    : "black";

  return (
    <Card.Content>
      <Statistic.Group size="small" widths="2">
        <Statistic color={Channel1.color}>
          <Statistic.Label>
            {titles[0].name + "(" + titles[0].unit + ")"}
          </Statistic.Label>
          <Statistic.Value>{Channel1.Value}</Statistic.Value>
        </Statistic>
        <Statistic color={Channel2.color}>
          <Statistic.Label>
            {titles[1].name + "(" + titles[1].unit + ")"}
          </Statistic.Label>
          <Statistic.Value>{Channel2.Value}</Statistic.Value>
        </Statistic>
      </Statistic.Group>
    </Card.Content>
  );
}
