import React, { Component } from "react";
import {
  Segment,
  Icon,
  Header,
  Label,
  Responsive,
  List,
  Button
} from "semantic-ui-react";
import RoleLevelLabel from "../Base/RoleLevelLabel";

export default class GroupItem extends Component {
  render() {
    const { name, role, createdBy, createdOn } = this.props;
    const createdOnStr = new Date(createdOn).toLocaleString("en-IN");
    return (
      <React.Fragment>
        <Segment
          clearing
          as={Button}
          fluid
          onClick={() => this.props.selectGroupCallback({ ...this.props })}
          textAlign="left"
        >
          <Header as="h4" floated="left">
            <Icon name="cubes" />
            <Header.Content>
              {name}
              <RoleLevelLabel role={role} />
            </Header.Content>
          </Header>
          <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <Label basic style={{ float: "right" }}>
              <Icon name="clock outline" />
              {createdOnStr}
            </Label>
            <Label basic style={{ float: "right" }}>
              <Icon name="user secret" />
              {createdBy}
            </Label>
            {/*
            <Label basic style={{ float: "right" }}>
            <Icon name="tags" />5
            </Label>
          */}
          </Responsive>
          <br />
          <br />
          <Responsive {...Responsive.onlyMobile} as={List}>
            <List.Item icon="user secret" content={createdBy} />
            <List.Item icon="clock outline" content={createdOnStr} />
            {/*<List.Item icon="tags" content="5" />*/}
          </Responsive>
        </Segment>
      </React.Fragment>
    );
  }
}
