import React from "react";
import { Line } from "react-chartjs-2";
export default function ReportGraph(props) {
  const { data, device } = props;

  const myOptions = {
    scales: {
      xAxes: [
        {
          type: "time",
          distribution: "linear",
          time: {
            minUnit: "minute",
            round: "minute",
            displayFormats: {
              minute: "HH:mm",
              hour: "MMM D \n HH:mm"
            }
          }
        }
      ]
    },
    elements: {
      line: {
        tension: 0 // disables bezier curves
      }
    }
  };

  const myData = {
    labels: data.map(item => item.sk),
    datasets: [
      {
        label: device.channels[0].name + " (" + device.channels[0].unit + ")",
        fill: false,
        borderColor: "#FF6384",
        backgroundColor: "#FF6384",
        data: data.map(item =>
          item.payload.data.Connected ? item.payload.data.Channel1.Value : NaN
        )
      },
      {
        label: device.channels[1].name + " (" + device.channels[1].unit + ")",
        fill: false,
        borderColor: "#36A2EB",
        backgroundColor: "#36A2EB",
        data: data.map(item =>
          item.payload.data.Connected ? item.payload.data.Channel2.Value : NaN
        )
      },
      {
        label: device.channels[2].name + " (" + device.channels[2].unit + ")",
        fill: false,
        borderColor: "#FFCE56",
        backgroundColor: "#FFCE56",
        data: data.map(item =>
          item.payload.data.Connected ? item.payload.data.Channel3.Value : NaN
        )
      },
      {
        label: device.channels[3].name + " (" + device.channels[3].unit + ")",
        fill: false,
        borderColor: "#4BC0C0",
        backgroundColor: "#4BC0C0",
        data: data.map(item =>
          item.payload.data.Connected ? item.payload.data.Channel4.Value : NaN
        )
      },
      {
        label: device.channels[4].name + " (" + device.channels[4].unit + ")",
        fill: false,
        borderColor: "#9966FF",
        backgroundColor: "#9966FF",
        data: data.map(item =>
          item.payload.data.Connected ? item.payload.data.Channel5.Value : NaN
        )
      },
      {
        label: device.channels[5].name + " (" + device.channels[5].unit + ")",
        fill: false,
        borderColor: "#ff8f21",
        backgroundColor: "#ff8f21",
        data: data.map(item =>
          item.payload.data.Connected ? item.payload.data.Channel6.Value : NaN
        )
      },
      {
        label: device.channels[6].name + " (" + device.channels[6].unit + ")",
        fill: false,
        borderColor: "#7d4100",
        backgroundColor: "#7d4100",
        data: data.map(item =>
          item.payload.data.Connected ? item.payload.data.Channel7.Value : NaN
        )
      },
      {
        label: device.channels[7].name + " (" + device.channels[7].unit + ")",
        fill: false,
        borderColor: "#636363",
        backgroundColor: "#636363",
        data: data.map(item =>
          item.payload.data.Connected ? item.payload.data.Channel8.Value : NaN
        )
      }
    ]
  };

  return (
    <React.Fragment>
      <Line data={myData} options={myOptions} />
    </React.Fragment>
  );
}
