import React, { Component } from "react";
import { Card } from "semantic-ui-react";
import BaseCard from "./BaseCard";
export default class CardDeck extends Component {
  render() {
    const { items, role, onUpdate } = this.props;
    var option = [];
    Object.keys(items).map(key => {
      let obj = {
        as: BaseCard,
        key: items[key].device.sk,
        card: items[key].device,
        shadow: items[key].shadow,
        order: items[key].device.order,
        role: role,
        onUpdate: onUpdate
      };
      return option.push(obj);
    });
    option = option.sort((a, b) => {
      // console.log(b.order, a.order);
      return a.order - b.order;
    });
    // console.log(items);
    return (
      <React.Fragment>
        <Card.Group centered items={option} />
      </React.Fragment>
    );
  }
}
