import React from "react";
import { SignOut } from "aws-amplify-react";
import { Responsive, Menu, Icon } from "semantic-ui-react";

export default class SignOutButton extends SignOut {
  render() {
    return (
      <Menu.Item active color="brown" onClick={this.signOut}>
        <Icon name="log out" />
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          Logout
        </Responsive>
      </Menu.Item>
    );
  }
}
