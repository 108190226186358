import React from "react";
import UserItem from "./UserItem";
import { Segment, Button, Header } from "semantic-ui-react";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from "../../GraphQL/queries";
import * as mutation from "../../GraphQL/mutations";
import MyLoader from "../Base/MyLoader";
import AddUserPage from "./AddUserPage";
export default class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newUsers: []
    };
    this.userList = [];
  }

  render() {
    const { markForDelete } = this.state;

    const NewUserList = this.state.newUsers.map(item => (
      <UserItem
        key={item.pk}
        sk={item.pk}
        role={item.role}
        userRole={this.props.role}
        deleteClick={delUsername => {
          this.setState({ markForDelete: delUsername });
        }}
      />
    ));

    return (
      <React.Fragment>
        {markForDelete ? (
          <React.Fragment>
            <Segment>
              <Header>
                Confirm Delete ?
                <Header.Subheader>
                  <span style={{ color: "red" }}>{markForDelete}</span> will no
                  longer have access to this group
                </Header.Subheader>
              </Header>
              <br />
              <Connect mutation={graphqlOperation(mutation.delUser)}>
                {({ mutation }) => (
                  <Button
                    negative
                    onClick={async () => {
                      const response = await mutation({
                        groupID: this.props.groupID,
                        username: markForDelete
                      });
                      console.log(response);
                      this.setState({ markForDelete: null });
                    }}
                  >
                    Yes
                  </Button>
                )}
              </Connect>
              <Button
                positive
                onClick={() => this.setState({ markForDelete: null })}
              >
                No
              </Button>
            </Segment>
          </React.Fragment>
        ) : (
          <Segment.Group>
            <Connect
              query={graphqlOperation(queries.listGroupUserMap, {
                groupID: this.props.groupID
              })}
            >
              {({ data, error, loading }) => {
                if (error) {
                  return <Header>Error :{error}</Header>;
                }
                if (loading || !data.listGroupUserMap) {
                  return <MyLoader />;
                }
                return data.listGroupUserMap.map(item => {
                  this.userList.push(item.sk);
                  return (
                    <UserItem
                      key={item.sk}
                      {...item}
                      userRole={this.props.role}
                      deleteClick={delUsername => {
                        this.setState({ markForDelete: delUsername });
                      }}
                    />
                  );
                });
              }}
            </Connect>
            {NewUserList}
            {this.props.role === 100 ? (
              <Segment textAlign="center" color="green">
                <Connect mutation={graphqlOperation(mutation.addUser)}>
                  {({ mutation }) => (
                    <AddUserPage
                      userList={this.userList}
                      groupID={this.props.groupID}
                      onCreate={async input => {
                        const response = await mutation({ ...input });
                        console.log(response);
                        this.setState({
                          newUsers: [
                            ...this.state.newUsers,
                            response.data.addUser
                          ]
                        });
                        this.userList.push(response.data.addUser.pk);
                      }}
                    />
                  )}
                </Connect>
              </Segment>
            ) : null}
          </Segment.Group>
        )}
      </React.Fragment>
    );
  }
}
