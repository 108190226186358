import React from "react";
import { Statistic, Grid, Icon, Label } from "semantic-ui-react";

export default function THPChannel(props) {
  const {
    Value,
    AlarmHigh,
    AlarmLow,
    AlarmHighSP,
    AlarmLowSP,
    name,
    unit,
    color,
    Connected
  } = props;
  return (
    <Statistic>
      <Statistic color={color}>
        <Statistic.Label>
          {name} ({unit})
        </Statistic.Label>
        <Statistic.Value>
          <Grid columns="equal">
            <Grid.Column className={AlarmLow ? "Stat-alert" : null}>
              <Label basic color="teal">
                {AlarmLowSP}
                <br />
                <Icon name="chevron down" />
              </Label>
            </Grid.Column>
            <Grid.Column
              className={AlarmHigh || AlarmLow ? "Stat-alert" : ""}
              width="8"
            >
              {AlarmLow ? <Icon name="arrow down" size="mini" /> : null}
              {Connected ? Value : "N/C"}
              {AlarmHigh ? <Icon name="arrow up" size="mini" /> : null}
            </Grid.Column>
            <Grid.Column className={AlarmHigh ? "Stat-alert" : null}>
              <Label basic color="orange">
                <Icon name="chevron up" />
                <br />
                {AlarmHighSP}
              </Label>
            </Grid.Column>
          </Grid>
        </Statistic.Value>
      </Statistic>
    </Statistic>
  );
}
