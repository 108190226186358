import React, { Component } from "react";
import GroupItem from "./GroupItem";
import * as queries from "../../GraphQL/queries";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { Header } from "semantic-ui-react";
export default class GroupList extends Component {
  render() {
    const ListView = ({ items }) => (
      <div>
        {items.map(item => (
          <GroupItem key={item.sk} {...item} {...this.props} />
        ))}
      </div>
    );

    return (
      <React.Fragment>
        <Connect query={graphqlOperation(queries.listUserGroupMap)}>
          {({ data, error, loading }) => {
            if (error) {
              return <Header>Error :{error}</Header>;
            }
            if (loading || !data.listUserGroupMap) {
              return <Header>Loading...</Header>;
            }
            const sortedItems = data.listUserGroupMap.sort((a, b) => {
              var x = a.name.toLowerCase();
              var y = b.name.toLowerCase();
              if (x < y) {
                return -1;
              }
              if (x > y) {
                return 1;
              }
              return 0;
            });
            return <ListView items={sortedItems} />;
          }}
        </Connect>
      </React.Fragment>
    );
  }
}
