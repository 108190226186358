import React, { Component } from "react";
import { Card, Statistic } from "semantic-ui-react";
import CardHeaders from "../CardHeaders";
import ContentPID from "./content-PID";
import ContentDPI from "./content-DPI";
import Actions from "./Actions";

export default class JLCGEMCard extends Component {
	render() {
		// console.log(this.props);
		const { card, shadow, role, onUpdate } = this.props;
		return (
			<React.Fragment>
				<Card raised style={{ minWidth: 320, minHeight: 100 }}>
					<CardHeaders {...card} updatedOn={new Date(shadow.Timestamp)} />
					<Card.Content>
						<Statistic.Group size="small" widths="1">
							{shadow.data.MPM.map((item, index) => (
								<ContentDPI
									key={index}
									{...item}
									titles={card.channels[index]}
								/>
							))}
						</Statistic.Group>
					</Card.Content>
					<Card.Content>
						<Statistic.Group size="small" widths="2">
							{shadow.data.PID.map((item, index) => (
								<ContentPID
									key={index}
									{...item}
									titles={card.channels[index + shadow.data.MPM.length]}
								/>
							))}
						</Statistic.Group>
					</Card.Content>

					{role >= 75 ? (
						<Actions {...shadow} {...card} onUpdate={onUpdate} />
					) : null}
				</Card>
			</React.Fragment>
		);
	}
}
