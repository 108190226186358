import React from "react";
import { Statistic, Label } from "semantic-ui-react";

export default function ContentEnergyMeter(props) {
  const { Phases } = props;
  return (
    <React.Fragment>
      {Phases.map((phase, index) => {
        const lblColor =
          index === 0 ? "orange" : index === 1 ? "green" : "purple";

        return (
          <React.Fragment key={phase.phase}>
            <Statistic.Group widths="3" size="tiny" color={lblColor}>
              <Label horizontal circular basic color={lblColor}>
                Phase: {phase.phase}
              </Label>
              <Statistic horizontal>
                <Statistic.Value>{Math.round(phase.V)}</Statistic.Value>
                <Statistic.Label>V</Statistic.Label>
              </Statistic>
              <Statistic horizontal size="tiny">
                <Statistic.Value>{Math.round(phase.I)}</Statistic.Value>
                <Statistic.Label>A</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
