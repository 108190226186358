import React, { Component } from "react";
import { Container, Header, Icon } from "semantic-ui-react";
import UserList from "./UserList";

export default class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Container style={{ width: 320 }}>
          <Header as="h3">
            <Icon name="users" />
            Users
          </Header>
          <UserList {...this.props} />
        </Container>
      </React.Fragment>
    );
  }
}
