import React, { Component } from "react";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { selectDataPointType } from "../Base/Helper";
import { Message, Menu, Icon } from "semantic-ui-react";
import MyLoader from "../Base/MyLoader";
import LoadedReport from "./LoadedReport";

export default class BaseReport extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showTable: true,
			showGraph: true
		};
	}

	handleShowHideClick = name => () => {
		this.setState({
			[name]: !this.state[name]
		});
	};

	handlePrintClick = () => {
		window.print();
	};

	render() {
		const { device, startDate, endDate } = this.props;
		const { showGraph, showTable } = this.state;
		const noPrint = showTable || showGraph ? false : true;

		if (device) {
			const dataFormat = selectDataPointType(device.guiID);

			return (
				<React.Fragment>
					<Connect
						query={graphqlOperation(dataFormat, {
							thing: device.sk,
							groupID: device.pk,
							startDate: startDate,
							endDate: endDate
						})}
					>
						{({ data, error, loading }) => {
							if (error) {
								return (
									<React.Fragment>
										<Message error>{error}</Message>
									</React.Fragment>
								);
							}
							if (loading || !data.getDataPoints) {
								return <MyLoader />;
							}
							return (
								<React.Fragment>
									<Menu attached="top" fluid widths="3" inverted>
										<Menu.Item
											color={showGraph ? "red" : "blue"}
											active
											onClick={this.handleShowHideClick("showGraph")}
										>
											<Icon name="chart line" />
											{showGraph ? "Hide" : "Show"}
										</Menu.Item>
										<Menu.Item
											color={showTable ? "red" : "blue"}
											active
											onClick={this.handleShowHideClick("showTable")}
										>
											<Icon name="table" />
											{showTable ? "Hide" : "Show"}
										</Menu.Item>
										<Menu.Item
											onClick={this.handlePrintClick}
											disabled={noPrint}
										>
											<Icon name="print" />
											Print
										</Menu.Item>
									</Menu>
									<LoadedReport
										data={data.getDataPoints}
										{...this.props}
										{...this.state}
									/>
								</React.Fragment>
							);
						}}
					</Connect>
				</React.Fragment>
			);
		} else return <React.Fragment />;
	}
}
