import React, { Component } from "react";
import { Form, Container, Message } from "semantic-ui-react";
import { API, graphqlOperation } from "aws-amplify";

import * as mutations from "../../GraphQL/mutations";
import { toast } from "react-toastify";
export default class NewGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: ""
    };
  }

  onNameChange = (event, data) => {
    if (data.value.length <= 16) {
      this.setState({
        groupName: data.value
      });
    }
  };

  onSubmit = () => {
    const { groupName } = this.state;
    const name = groupName;
    console.log(groupName);
    API.graphql(graphqlOperation(mutations.addGroup, { name }))
      .then(response => {
        toast(({ closeToast }) => (
          <Message
            success
            icon="check"
            header="Success"
            content="New Group Added"
            onDismiss={closeToast}
          />
        ));
        console.log(response);
        this.props.goBack();
      })
      .catch(error => {
        console.log(error);
        toast(({ closeToast }) => (
          <Message
            error
            icon="cross"
            header="Error"
            content="Failed to Add Group"
            onDismiss={closeToast}
          />
        ));
      });
  };

  render() {
    const SubmitDisable = this.state.groupName.length > 2 ? false : true;
    return (
      <React.Fragment>
        <Container style={{ width: 320 }}>
          <Form>
            <Form.Input
              label="Group Name: "
              value={this.state.groupName}
              onChange={this.onNameChange}
            />
            <Form.Button
              positive
              disabled={SubmitDisable}
              onClick={this.onSubmit}
            >
              Create Group
            </Form.Button>
          </Form>
        </Container>
      </React.Fragment>
    );
  }
}
