import React, { Component } from "react";
import { Card } from "semantic-ui-react";
import CardHeaders from "../CardHeaders";

//import NotifBar from "./NotifBar";
import Content from "./Content";
//import Extra from "./Extra";

import Actions from "./Actions";
export default class DualAICard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { card, shadow, role, onUpdate } = this.props;

    return (
      <React.Fragment>
        <Card raised style={{ minWidth: 340, minHeight: 320 }}>
          <CardHeaders {...card} updatedOn={new Date(shadow.Timestamp)} />
          <React.Fragment>
            <Content {...shadow.data} titles={card.channels} />
            {role >= 75 ? (
              <Actions {...shadow} {...card} onUpdate={onUpdate} />
            ) : null}
          </React.Fragment>
        </Card>
      </React.Fragment>
    );
  }
}
