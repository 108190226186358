import React from "react";
import { Card } from "semantic-ui-react";

export function ExtraUP(props) {
	const { Temperature, RH, titles } = props;
	return (
		<Card.Content extra textAlign="center">
			<span style={{ float: "left" }}>
				{Temperature.AlarmHighSP + " " + titles[0].unit}
			</span>
			Alarm High
			<span style={{ float: "right" }}>
				{RH.AlarmHighSP + " " + titles[0].unit}
			</span>
		</Card.Content>
	);
}

export function ExtraDown(props) {
	const { Temperature, RH, titles } = props;
	return (
		<Card.Content extra textAlign="center">
			<span style={{ float: "left" }}>
				{Temperature.AlarmLowSP + " " + titles[0].unit}
			</span>
			Alarm Low
			<span style={{ float: "right" }}>
				{RH.AlarmLowSP + " " + titles[0].unit}
			</span>
		</Card.Content>
	);
}
