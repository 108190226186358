import React from "react";
import { Line } from "react-chartjs-2";
export default function ReportGraph(props) {
	const { data } = props;

	const myOptions = {
		scales: {
			xAxes: [
				{
					type: "time",
					distribution: "linear",
					time: {
						minUnit: "minute",
						round: "minute",
						displayFormats: {
							minute: "HH:mm",
							hour: "MMM D \n HH:mm",
						},
					},
				},
			],
			yAxes: [
				{
					id: "left-y-axis",
					type: "linear",
					position: "left",
					scaleLabel: {
						display: true,
						labelString: "Power",
					},
				},
				{
					id: "right-y-axis",
					type: "linear",
					position: "right",
					scaleLabel: {
						display: true,
						labelString: "Harmonic Distortion (%) ",
					},
				},
			],
		},

		elements: {
			line: {
				tension: 0, // disables bezier curves
			},
		},
	};

	const myData = {
		labels: data.map((item) => item.sk),
		datasets: [
			{
				label: "Total KW",
				fill: false,
				borderColor: "#FF6384",
				backgroundColor: "#FF6384",
				data: data.map((item) =>
					item.payload.data.Connected ? item.payload.data.Phase.T_active : NaN
				),
			},
			{
				label: "Total KVAR",
				fill: false,
				borderColor: "#36A2EB",
				backgroundColor: "#36A2EB",
				data: data.map((item) =>
					item.payload.data.Connected ? item.payload.data.Phase.T_reactive : NaN
				),
			},
			{
				label: "Total KVA",
				fill: false,
				borderColor: "#FFCE56",
				backgroundColor: "#FFCE56",
				data: data.map((item) =>
					item.payload.data.Connected ? item.payload.data.Phase.T_apparent : NaN
				),
			},
			{
				label: "THDV (%)",
				fill: false,
				borderColor: "#4BC0C0",
				backgroundColor: "#4BC0C0",
				yAxisID: "right-y-axis",
				data: data.map((item) =>
					item.payload.data.Connected ? item.payload.data.Phase.THDV : NaN
				),
			},
			{
				label: "THDI (%)",
				fill: false,
				borderColor: "#9966FF",
				backgroundColor: "#9966FF",
				yAxisID: "right-y-axis",
				data: data.map((item) =>
					item.payload.data.Connected ? item.payload.data.Phase.THDI : NaN
				),
			},
		],
	};

	return (
		<React.Fragment>
			<Line data={myData} options={myOptions} />
		</React.Fragment>
	);
}
