import React from "react";
import { Label } from "semantic-ui-react";

export default function RoleLevelLabel(props) {
  const { role } = props;

  var RoleText = "";
  var RoleColor = "";
  switch (role) {
    case 100:
      RoleText = "Owner";
      RoleColor = "blue";
      break;
    case 75:
      RoleText = "Manager";
      RoleColor = "purple";
      break;
    case 50:
      RoleText = "Supervisor";
      RoleColor = "brown";
      break;
    case 25:
      RoleText = "Observer";
      RoleColor = "grey";
      break;
    default:
      RoleText = "Others";
      RoleColor = "black";
      break;
  }

  return (
    <React.Fragment>
      <Label color={RoleColor} horizontal circular basic>
        {RoleText}
      </Label>
    </React.Fragment>
  );
}
