import React from "react";
import { Segment, Header, Icon } from "semantic-ui-react";
import RoleLevelLabel from "../Base/RoleLevelLabel";

export default function UserItem(props) {
  const { sk, role, userRole, deleteClick } = props;

  return (
    <React.Fragment>
      <Segment clearing>
        <Header as="h4" floated="left">
          {sk}
          <RoleLevelLabel role={role} />
        </Header>

        {role !== 100 ? (
          <Icon
            name="user delete"
            color="red"
            style={{ float: "right" }}
            disabled={userRole < 100}
            onClick={() => deleteClick(sk)}
          />
        ) : null}
      </Segment>
    </React.Fragment>
  );
}
