import React from "react";
import { Table } from "semantic-ui-react";

export default function ReportTable(props) {
  const { device, data } = props;
  console.log(data);
  return (
    <React.Fragment>
      <Table unstackable compact celled textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            {device.channels.map((item, index) => {
              return (
                <Table.HeaderCell key={index}>
                  {item.name + " (" + item.unit + ")"}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body
          children={data.map(item => (
            <Table.Row key={item.sk}>
              <Table.Cell>{item.sk}</Table.Cell>
              <Table.Cell
                positive={item.payload.data.Channel1.AlarmLow}
                negative={item.payload.data.Channel1.AlarmHigh}
              >
                {item.payload.data.Channel1.Value}
              </Table.Cell>
              <Table.Cell
                positive={item.payload.data.Channel2.AlarmLow}
                negative={item.payload.data.Channel2.AlarmHigh}
              >
                {item.payload.data.Channel2.Value}
              </Table.Cell>
            </Table.Row>
          ))}
        />
      </Table>
    </React.Fragment>
  );
}
