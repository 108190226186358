import React from "react";
import { Line } from "react-chartjs-2";
export default function ReportGraph(props) {
  const { data, device } = props;

  const myOptions = {
    scales: {
      xAxes: [
        {
          type: "time",
          distribution: "linear",
          time: {
            minUnit: "minute",
            round: "minute",
            displayFormats: {
              minute: "HH:mm",
              hour: "MMM D \n HH:mm"
            }
          }
        }
      ]
    },
    elements: {
      line: {
        tension: 0 // disables bezier curves
      }
    }
  };
  const charColor = ["#ff6384", "#36a2eb", "#4bc0c0", "#ffce56"];
  const myData = {
    labels: data.map(item => item.sk),
    datasets: device.channels.map((item, index) => {
      return {
        label: item.name + " (" + item.unit + ")",
        fill: false,
        borderColor: charColor[index],
        backgroundColor: charColor[index],
        data: data.map(item => {
          const { Value, Connected } = item.payload.data.PID[index];
          if (Connected) {
            return Value;
          } else {
            return Number.NaN;
          }
        })
      };
    })
  };

  return (
    <React.Fragment>
      <Line data={myData} options={myOptions} />
    </React.Fragment>
  );
}
