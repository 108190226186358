import React from "react";
import { Table } from "semantic-ui-react";

export default function ReportTable(props) {
	const { device, data } = props;
	console.log(data);
	return (
		<React.Fragment>
			<Table unstackable compact celled textAlign="center">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Timestamp</Table.HeaderCell>
						{device.channels.map((item, index) => {
							return (
								<Table.HeaderCell key={index}>
									{item.name + " (" + item.unit + ")"}
								</Table.HeaderCell>
							);
						})}
					</Table.Row>
				</Table.Header>
				<Table.Body
					children={data.map((item) => (
						<Table.Row key={item.sk}>
							<Table.Cell>{item.sk}</Table.Cell>
							<Table.Cell
								positive={item.payload.data.Temperature.AlarmLow}
								negative={item.payload.data.Temperature.AlarmHigh}
							>
								{item.payload.data.Temperature.Value}
							</Table.Cell>
							<Table.Cell
								positive={item.payload.data.RH.AlarmLow}
								negative={item.payload.data.RH.AlarmHigh}
							>
								{item.payload.data.RH.Value}
							</Table.Cell>
						</Table.Row>
					))}
				/>
			</Table>
		</React.Fragment>
	);
}
