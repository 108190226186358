import React from "react";
import { Table } from "semantic-ui-react";

export default function ReportTable(props) {
	const { data } = props;
	console.log(data);
	return (
		<React.Fragment>
			<Table unstackable compact celled textAlign="center">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Timestamp</Table.HeaderCell>
						<Table.HeaderCell>Total KW</Table.HeaderCell>
						<Table.HeaderCell>Total KVAR</Table.HeaderCell>
						<Table.HeaderCell>Total KVA</Table.HeaderCell>
						<Table.HeaderCell>THDV(%)</Table.HeaderCell>
						<Table.HeaderCell>THDI (%)</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body
					children={data.map((item) => (
						<Table.Row key={item.sk}>
							<Table.Cell>{item.sk}</Table.Cell>
							<Table.Cell>{item.payload.data.Phase.T_active}</Table.Cell>
							<Table.Cell>{item.payload.data.Phase.T_reactive}</Table.Cell>
							<Table.Cell>{item.payload.data.Phase.T_apparent}</Table.Cell>
							<Table.Cell>{item.payload.data.Phase.THDV}</Table.Cell>
							<Table.Cell>{item.payload.data.Phase.THDI}</Table.Cell>
						</Table.Row>
					))}
				/>
			</Table>
		</React.Fragment>
	);
}
