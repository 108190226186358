import React, { Component } from "react";
import { Card, Icon } from "semantic-ui-react";

export default class CardUpdatedOn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updated: "Just now",
      showWarning: false
    };
  }

  getUpdateDuration() {
    const currentDate = new Date();
    let datetimeDiff = currentDate - this.props.date;
    datetimeDiff = datetimeDiff / 1000;
    if (datetimeDiff < 10) {
      this.setState({
        updated: "Just now",
        showWarning: false
      });
    } else if (datetimeDiff >= 10 && datetimeDiff < 60) {
      this.setState({
        updated: Math.round(datetimeDiff) + " sec ago",
        showWarning: false
      });
    } else if (datetimeDiff > 60 && datetimeDiff < 120) {
      this.setState({
        updated: Math.floor(datetimeDiff / 60) + " min ago",
        showWarning: false
      });
    } else if (datetimeDiff >= 120 && datetimeDiff < 3600) {
      this.setState({
        updated: Math.floor(datetimeDiff / 60) + " mins ago",
        showWarning: false
      });
    } else if (datetimeDiff >= 3600 && datetimeDiff < 7200) {
      this.setState({
        updated: Math.floor(datetimeDiff / 3600) + " hour ago",
        showWarning: true
      });
    } else if (datetimeDiff >= 7200) {
      this.setState({
        updated: Math.floor(datetimeDiff / 3600) + " hours ago",
        showWarning: true
      });
    }
  }

  componentDidMount() {
    this.getUpdateDuration();
    this.intervalUpdate = setInterval(() => this.getUpdateDuration(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalUpdate);
  }

  render() {
    return (
      <React.Fragment>
        <Card.Meta textAlign="right">
          {this.state.showWarning ? (
            <Icon color="red" name="exclamation" />
          ) : null}
          Updated: {this.state.updated}
        </Card.Meta>
      </React.Fragment>
    );
  }
}
