import React from "react";
import MainScreen from "../Base/MainScreen";

export default function AppWithAuth(props) {
  if (props.authState === "signedIn") {
    return (
      <React.Fragment>
        <MainScreen />
      </React.Fragment>
    );
  } else return null;
}
