import React, { Component } from "react";
import { Card, Statistic, Header } from "semantic-ui-react";
import CardHeaders from "../CardHeaders";

import NotifBar from "./NotifBar";
import ContentTHP from "./content-THP";
import ContentManviPID from "./content-ManviPID";
import Actions from "./Actions";

export default class DualAICard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { card, shadow, role, onUpdate } = this.props;
    return (
      <React.Fragment>
        <Card raised style={{ minWidth: 320, minHeight: 320 }}>
          <NotifBar {...shadow.data} />
          <CardHeaders {...card} updatedOn={new Date(shadow.Timestamp)} />
          <ContentTHP {...shadow.data.THP[0]} titles={card.channels} />
          <Card.Content>
            <Header as="h3" textAlign="center">
              Control Set Points
            </Header>
            <Statistic.Group size="small" widths="2">
              {shadow.data.PID.map((item, index) => (
                <ContentManviPID
                  key={index}
                  {...item}
                  titles={card.channels[index + 2]}
                />
              ))}
            </Statistic.Group>
          </Card.Content>
          {role >= 75 ? (
            <Actions {...shadow} {...card} onUpdate={onUpdate} />
          ) : null}
        </Card>
      </React.Fragment>
    );
  }
}
