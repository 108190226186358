import React from "react";
import { Card, Icon, Label } from "semantic-ui-react";

const NotifBar = (props) => {
	const { bit0, bit1, bit2, bit5, bit6, bit7 } = props.Alarm;

	return (
		<Card.Content extra>
			{bit0 || bit1 || bit2 || bit5 || bit6 || bit7 ? (
				<Icon name="alarm" color="red" size="large"></Icon>
			) : (
				<Icon name="alarm mute" color="green" size="large"></Icon>
			)}
			{bit0 ? <Label color="red">Under Voltage</Label> : null}
			{bit1 ? <Label color="red">Under Current</Label> : null}
			{bit2 ? <Label color="red">THDI</Label> : null}
			{bit5 ? <Label color="red">CosΦ</Label> : null}
			{bit6 ? <Label color="red">Zero Stage</Label> : null}
			{bit7 ? <Label color="red">Stage Counter</Label> : null}
		</Card.Content>
	);
};

export default NotifBar;
