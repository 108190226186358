import React from "react";
import { Card } from "semantic-ui-react";

export default function Extra(props) {
  const { Channel1, Channel2, titles } = props;
  return (
    <Card.Content extra>
      <span style={{ float: "left" }}>
        Set Point: {Channel1.SetPoint + " " + titles[0].unit}
      </span>
      <span style={{ float: "right" }}>
        Set Point: {Channel2.SetPoint + " " + titles[1].unit}
      </span>
    </Card.Content>
  );
}
