import React from "react";
import { Statistic, Label } from "semantic-ui-react";
export default function ContentManviPID(props) {
  const { SetPoint, titles, Alarm, Connected, Output, ControlAction } = props;
  //console.log(props);

  var ValColor = "black";

  Alarm.map(alarm => {
    if (alarm.Type === "HIGH" && alarm.Status === true) {
      return (ValColor = "red");
    }
    if (alarm.Type === "LOW" && alarm.Status === true) {
      return (ValColor = "blue");
    }
    return null;
  });

  ValColor = Connected ? ValColor : "brown";

  return (
    <React.Fragment>
      <Statistic color={ValColor}>
        <Statistic.Label>
          {titles.name + " (" + titles.unit + ")"}
        </Statistic.Label>
        <Statistic.Value>{Connected ? SetPoint : "N/C"}</Statistic.Value>
        <Label basic size="tiny" color="grey">
          Output: {ControlAction ? Output + " %" : "OFF"}
        </Label>
      </Statistic>
    </React.Fragment>
  );
}
