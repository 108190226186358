import React from "react";
import { Card, Icon } from "semantic-ui-react";
export default function DualAINotifBar(props) {
	console.log(props);
	const { RH, ControlAction } = props;

	const faultStatus = RH.AlarmHigh || RH.AlarmLow ? true : false;

	const errorStatus = isNaN(RH.Value) && RH.Value !== "OFF" ? true : false;

	return (
		<Card.Content extra>
			{faultStatus ? (
				<Icon
					name="warning sign"
					color="orange"
					size="large"
					style={{ float: "right" }}
				/>
			) : null}
			{errorStatus ? (
				<Icon
					name="warning circle"
					color="red"
					size="large"
					style={{ float: "right" }}
				/>
			) : null}
			{faultStatus || errorStatus ? null : (
				<Icon name="heart" color="red" size="large" style={{ float: "left" }} />
			)}
			{ControlAction ? <Icon name="power" color="green" size="large" /> : null}
		</Card.Content>
	);
}
