import React from "react";
import {
  Card,
  Button,
  Icon,
  Modal,
  Header,
  Form,
  Message
} from "semantic-ui-react";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as mutation from "../../../GraphQL/mutations";
import { toast } from "react-toastify";

export default class Actions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      PID: [],
      channels: [],
      settingsOpen: false
    };
  }

  handleConfigOpen = () => {
    const { title, subtitle, order, channels } = this.props;
    this.setState({
      configOpen: true,
      title: title,
      subtitle: subtitle,
      order: order,
      channels: channels
    });
  };

  handleConfigClose = () => this.setState({ configOpen: false });

  handleChannelChange = (event, data) => {
    this.setState(state => {
      const channels = state.channels.map((item, index) => {
        const newItem = { ...item };
        if (data.index === index) {
          newItem[data.name] = data.value;
        }
        return newItem;
      });
      return { channels };
    });
  };

  onConfigChange = (event, data) => {
    if (data.value.length < 20) {
      this.setState({ [data.name]: data.value });
    }
  };

  onOrderChange = (event, data) => {
    if (
      (Number.isInteger(Number(data.value)) &&
        data.value > 0 &&
        data.value <= 50) ||
      data.value === ""
    ) {
      this.setState({ [data.name]: data.value });
    }
  };

  SuccessMessage = message => ({ closeToast }) => (
    <Message
      success
      icon="check"
      header="Success"
      content={message}
      onDismiss={closeToast}
    />
  );

  ErrorMessage = message => ({ closeToast }) => (
    <Message
      error
      icon="warning circle"
      header="Error"
      content={message}
      onDismiss={closeToast}
    />
  );

  render() {
    const { pk, sk, onUpdate } = this.props;
    const { configOpen, title, subtitle, order, channels } = this.state;

    const configUpdateDisabled = !(
      title &&
      subtitle &&
      order &&
      channels.reduce((acc, cur) => {
        return acc && cur.name;
      }, true)
    );

    return (
      <Card.Content extra>
        <Modal
          open={configOpen}
          onClose={this.handleConfigClose}
          size="mini"
          trigger={
            <Button
              floated="right"
              basic
              compact
              onClick={this.handleConfigOpen}
            >
              <Icon name="configure" />
              Configure
            </Button>
          }
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Header as="h4">
            <Icon name="configure" />
            <Header.Content>
              Configure
              <Header.Subheader>{sk}</Header.Subheader>
            </Header.Content>
          </Header>
          <Modal.Content>
            <Form>
              <Form.Input
                label="Title"
                value={title}
                name="title"
                onChange={this.onConfigChange}
              />
              <Form.Input
                label="Subtitle"
                value={subtitle}
                name="subtitle"
                onChange={this.onConfigChange}
              />
              <Form.Input
                label="Order"
                value={order}
                name="order"
                onChange={this.onOrderChange}
              />
              <Header>Channel Headers</Header>
              {channels.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Form.Group widths={2} unstackable>
                      <Form.Input
                        label="Name"
                        value={item.name}
                        index={index}
                        name="name"
                        onChange={this.handleChannelChange}
                      />
                      <Form.Input
                        label="Unit"
                        value={item.unit}
                        index={index}
                        name="unit"
                        onChange={this.handleChannelChange}
                      />
                    </Form.Group>
                  </React.Fragment>
                );
              })}
              <Connect mutation={graphqlOperation(mutation.UpdateDevice)}>
                {({ mutation }) => (
                  <Button
                    disabled={configUpdateDisabled}
                    positive
                    onClick={() => {
                      const input = {
                        name: sk,
                        title: title,
                        subtitle: subtitle,
                        order: Number(order),
                        channels: channels
                      };
                      mutation({
                        input: input,
                        groupID: pk
                      })
                        .then(response => {
                          console.log(response);
                          const {
                            name,
                            title,
                            subtitle,
                            order,
                            channels
                          } = response.data.updateDevice;
                          onUpdate(name, title, subtitle, order, channels);
                          toast(this.SuccessMessage("Config Updated"));
                          this.handleConfigClose();
                        })
                        .catch(err => {
                          console.error(err);
                          toast(this.ErrorMessage("Update Failed"));
                          this.handleConfigClose();
                        });
                    }}
                  >
                    Update
                  </Button>
                )}
              </Connect>
            </Form>
          </Modal.Content>
        </Modal>
      </Card.Content>
    );
  }
}
