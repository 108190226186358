export const listUserGroupMap = `query listUserGroupMap{
    listUserGroupMap{
      pk
      sk
      name
      role
      createdBy
      createdOn
    }
  }
`;

export const listGroupThingsMap = `query listGroupThingsMap($pk: String!){
  listGroupThingMap(pk: $pk) {
    pk
    sk
    createdOn
    createdBy
    guiID
    model
    name
    order
    subtitle
    title
    channels {
      name
      unit
    }
  }
}
`;

export const getShadowDualAI = `query getShadow($thing: String! $groupID: String){
  getDeviceShadow(thing: $thing, groupID: $groupID){
    ThingName
    Timestamp
    Model
    OwnerID
    data{
      ...on DualAIShadow{
        Channel1 {
          Value
          AlarmHigh
          AlarmLow
          SetPoint
          AlarmHighSP
          AlarmLowSP
        }
        Channel2 {
          Value
          AlarmHigh
          AlarmLow
          SetPoint
          AlarmHighSP
          AlarmLowSP
        }
      }
    }
  }
}
`;

export const getManvi01Shadow = `query getShadow($thing: String! $groupID: String){
  getDeviceShadow(thing: $thing, groupID: $groupID){
    ThingName
    Timestamp
    Model
    OwnerID
    data{
      ...on Manvi01Shadow{
        RH {
          Value
          AlarmHigh
          AlarmLow
          SetPoint
          AlarmHighSP
          AlarmLowSP
        }
        Temperature {
          Value
          AlarmHigh
          AlarmLow
          SetPoint
          AlarmHighSP
          AlarmLowSP
        }
        ControlAction
        Output
      }
    }
  }
}
`;

export const getManvi02Shadow = `query getShadow($thing: String! $groupID: String){
  getDeviceShadow(thing: $thing, groupID: $groupID){
    ThingName
    Timestamp
    Model
    OwnerID
    data{
      ...on Manvi02Shadow{
        PID {
          slaveID
          Value
          SetPoint
          Connected
          Output
          ControlAction
          Alarm {
            Type
            Set
            Status
          }
        }
        THP {
          slaveID
          TEMP
          RH
          Connected
        }
      }
    }
  }
}`;

export const getJLCGEMShadow = `query getJLCShadow($thing: String! $groupID: String){
  getDeviceShadow(thing: $thing, groupID: $groupID){
    ThingName
    Timestamp
    Model
    OwnerID
    data{
      ...on JLCGEMShadow{
        PID {
          slaveID
          Value
          SetPoint
          Connected
          Alarm {
            Type
            Set
            Status
          }
        }
        MPM {
          slaveID
          RPM
          MPM
          SpanHigh
          Diameter
          Connected
        }
      }
    }
  }
}`;

export const getJLCSAFShadow = `query getJLCShadow($thing: String! $groupID: String){
  getDeviceShadow(thing: $thing, groupID: $groupID){
    ThingName
    Timestamp
    Model
    OwnerID
    data{
      ...on JLCSAFShadow{
        PID {
          slaveID
          Value
          SetPoint
          Connected
          ControlAction
          SPNL
          SPNH
          Alarm {
            Type
            Set
            Status
          }
        }
        EnergyMeter {
          slaveID
          Phases{
            phase
            V
            I
          }
          Connected
        }
      }
    }
  }
}`;

export const getMultiPIDShadow = `query getMultiPIDShadow($thing: String! $groupID: String){
  getDeviceShadow(thing: $thing, groupID: $groupID){
    ThingName
    Timestamp
    Model
    OwnerID
    data{
      ...on MultiPIDShadow{
        PID {
          slaveID
          Value
          SetPoint
          Connected
          ControlAction
          SPNL
          SPNH
          Alarm {
            Type
            Set
            Status
          }
        }
      }
    }
  }
}`;

export const getMultiChannelShadow = `query getMultiChannelShadow($thing: String! $groupID: String){
  getDeviceShadow(thing: $thing, groupID: $groupID){
    ThingName
    Timestamp
    Model
    OwnerID
    data{
      ... on MultiChannelShadow{
        Channel1 {
          Value
          AlarmHigh
          AlarmLow
        }
        Channel2 {
          Value
          AlarmHigh
          AlarmLow
        }
        Channel3 {
          Value
          AlarmHigh
          AlarmLow
        }
        Channel4 {
          Value
          AlarmHigh
          AlarmLow
        }
        Channel5 {
          Value
          AlarmHigh
          AlarmLow
        }
        Channel6 {
          Value
          AlarmHigh
          AlarmLow
        }
        Channel7 {
          Value
          AlarmHigh
          AlarmLow
        }
        Channel8 {
          Value
          AlarmHigh
          AlarmLow
        }
        ControlAction
        Connected
      }
    }
  }
}`;

export const getTHPShadow = `query getShadow($thing: String! $groupID: String){
  getDeviceShadow(thing: $thing, groupID: $groupID){
    ThingName
    Timestamp
    Model
    OwnerID
    data{
      ...on THPShadow{
        Temperature {
          Value
          AlarmHigh
          AlarmLow
          AlarmHighSP
          AlarmLowSP
        }
        RH {
          Value
          AlarmHigh
          AlarmLow
          AlarmHighSP
          AlarmLowSP
        }
        DP {
          Value
          AlarmHigh
          AlarmLow
          AlarmHighSP
          AlarmLowSP
        }
        Connected
      }
    }
  }
}
`;

export const listGroupUserMap = `query listGroupUserMap($groupID: String)
{
  listGroupUserMap(groupID: $groupID) {
    pk
    sk
    role
  }
}
`;

export const getDualAIData = `query getData($thing:String,$groupID:String,$startDate:String,$endDate:String){
  getDataPoints(thing:$thing,groupID:$groupID,startDate:$startDate,endDate:$endDate){
    pk
    sk
    payload{
      data{
        ...on DualAIShadow{
          Channel1 {
            Value
            AlarmHigh
            AlarmLow
          }
          Channel2{
            Value
            AlarmHigh
            AlarmLow
          }
        }
      }
    }
  }
}
`;

export const getJLCSAFData = `query getData($thing:String,$groupID:String,$startDate:String,$endDate:String){
  getDataPoints(thing:$thing,groupID:$groupID,startDate:$startDate,endDate:$endDate){
    pk
    sk
    payload{
      data{
        ...on JLCSAFShadow{
          PID {
            slaveID
            Value
            SetPoint
            Connected
            Alarm {
              Type
              Set
              Status
            }
          }
        }
      }
    }
  }
}
`;

export const getManvi01Data = `query getData($thing:String,$groupID:String,$startDate:String,$endDate:String){
  getDataPoints(thing:$thing,groupID:$groupID,startDate:$startDate,endDate:$endDate){
    pk
    sk
    payload{
      data{
        ...on Manvi01Shadow{
          Temperature{
            Value
            AlarmHigh
            AlarmLow
          }
          RH{
            Value
            AlarmHigh
            AlarmLow
            SetPoint
          }
        }
      }
    }
  }
}
`;

export const getManvi02Data = `query getData($thing:String,$groupID:String,$startDate:String,$endDate:String){
  getDataPoints(thing:$thing,groupID:$groupID,startDate:$startDate,endDate:$endDate){
    pk
    sk
    payload{
      data{
        ...on Manvi02Shadow{
          THP{
            RH
            TEMP
            Connected
          }
          PID {
            slaveID
            Value
            SetPoint
            Connected
            Alarm {
              Type
              Set
              Status
            }
          }
        }
      }
    }
  }
}
`;

export const MultiPIDData = `query getData($thing:String,$groupID:String,$startDate:String,$endDate:String){
  getDataPoints(thing:$thing,groupID:$groupID,startDate:$startDate,endDate:$endDate){
    pk
    sk
    payload{
      data{
        ...on MultiPIDShadow{
          PID {
            slaveID
            Value
            SetPoint
            Connected
            Alarm {
              Type
              Set
              Status
            }
          }
        }
      }
    }
  }
}
`;

export const MultiChannelData = `query getData($thing:String,$groupID:String,$startDate:String,$endDate:String){
  getDataPoints(thing:$thing,groupID:$groupID,startDate:$startDate,endDate:$endDate){
    pk
    sk
    payload{
      data{
        ... on MultiChannelShadow{
          Channel1 {
            Value
            AlarmHigh
            AlarmLow
          }
          Channel2 {
            Value
            AlarmHigh
            AlarmLow
          }
          Channel3 {
            Value
            AlarmHigh
            AlarmLow
          }
          Channel4 {
            Value
            AlarmHigh
            AlarmLow
          }
          Channel5 {
            Value
            AlarmHigh
            AlarmLow
          }
          Channel6 {
            Value
            AlarmHigh
            AlarmLow
          }
          Channel7 {
            Value
            AlarmHigh
            AlarmLow
          }
          Channel8 {
            Value
            AlarmHigh
            AlarmLow
          }
          Connected
        }
      }
    }
  }
}`;

export const THPData = `query getData($thing:String,$groupID:String,$startDate:String,$endDate:String){
  getDataPoints(thing:$thing,groupID:$groupID,startDate:$startDate,endDate:$endDate){
    pk
    sk
    payload{
      data{
        ... on THPShadow{
          Temperature {
            Value
            AlarmHigh
            AlarmLow
          }
          RH {
            Value
            AlarmHigh
            AlarmLow
          }
          DP {
            Value
            AlarmHigh
            AlarmLow
          }          
          Connected
        }
      }
    }
  }
}`;

export const JLCGEMData = `query getData($thing:String,$groupID:String,$startDate:String,$endDate:String){
  getDataPoints(thing:$thing,groupID:$groupID,startDate:$startDate,endDate:$endDate){
    pk
    sk
    payload{
      data{
        ...on JLCGEMShadow{
          PID {
            slaveID
            Value
            SetPoint
            Connected
            Alarm {
              Type
              Set
              Status
            }
          }
          MPM {
            slaveID
            RPM
            MPM
            SpanHigh
            Diameter
            Connected
          }
        }
      }
    }
  }
}
`;

export const getHTI3000Shadow = `query getShadow($thing: String! $groupID: String){
  getDeviceShadow(thing: $thing, groupID: $groupID){
    ThingName
    Timestamp
    Model
    OwnerID
    data{
      ...on HTI3000Shadow{
        Temperature {
          Value
          AlarmHigh
          AlarmLow 
          AlarmHighSP
          AlarmLowSP
        }
        RH {
          Value
          AlarmHigh
          AlarmLow
          AlarmHighSP
          AlarmLowSP
        }
      }
    }
  }
}
`;

export const getHTI3000Data = `query getData($thing:String,$groupID:String,$startDate:String,$endDate:String){
  getDataPoints(thing:$thing,groupID:$groupID,startDate:$startDate,endDate:$endDate){
    pk
    sk
    payload{
      data{
        ...on HTI3000Shadow{
          Temperature {
            Value
            AlarmHigh
            AlarmLow
          }
          RH{
            Value
            AlarmHigh
            AlarmLow
          }
        }
      }
    }
  }
}
`;

export const getClariant01Shadow = `query getShadow($thing: String! $groupID: String){
  getDeviceShadow(thing: $thing, groupID: $groupID){
    ThingName
    Timestamp
    Model
    OwnerID
    data{
      ...on Clariant01Shadow{
        Phase {
          cosph
          voltage
          current
          active
	        reactive
	        apparent
	        frequency
	        THDV
	        THDI
	        T_cosph
	        T_voltage
	        T_current
	        T_active
	        T_reactive
	        T_apparent
        }
        Relay {
          Rly1
          Rly2
          Rly3
          Rly4
          Rly5
          Rly6
          Rly7
          Rly8
          Rly9
          Rly10
          Rly11
          Rly12
        }
        Alarm {
          bit0
          bit1
          bit2
          bit3
          bit5
          bit6
          bit7
          bit8
        }
        Connected
      }
    }
  }
}
`;

export const getClariant01Data = `query getData($thing:String,$groupID:String,$startDate:String,$endDate:String){
  getDataPoints(thing:$thing,groupID:$groupID,startDate:$startDate,endDate:$endDate){
    pk
    sk
    payload{
      data{
        ...on Clariant01Shadow{
          Phase {
            cosph
            voltage
            current
            active
            reactive
            apparent
            frequency
            THDV
            THDI
            T_cosph
            T_voltage
            T_current
            T_active
            T_reactive
            T_apparent
          }
          Relay {
            Rly1
            Rly2
            Rly3
            Rly4
            Rly5
            Rly6
            Rly7
            Rly8
            Rly9
            Rly10
            Rly11
            Rly12
          }
          Alarm {
            bit0
            bit1
            bit2
            bit3
            bit5
            bit6
            bit7
            bit8
          }
          Connected
        }
      }
    }
  }
}
`;
