import React from "react";
import {
  Card,
  Button,
  Icon,
  Modal,
  Form,
  Message,
  // Responsive,
  Header
} from "semantic-ui-react";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as mutation from "../../../GraphQL/mutations";
import { toast } from "react-toastify";
// import { round } from "../../Base/Helper";

export default class Actions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settingsOpen: false
    };
  }

  onNameChange = (event, data) => {
    if (!Number.isNaN(data.value) && data.value >= 0 && data.value <= 100) {
      this.setState({ [data.name]: data.value });
    }
  };

  onConfigChange = (event, data) => {
    if (data.value.length < 20) {
      this.setState({ [data.name]: data.value });
    }
  };

  onOrderChange = (event, data) => {
    if (
      (Number.isInteger(Number(data.value)) &&
        data.value > 0 &&
        data.value <= 50) ||
      data.value === ""
    ) {
      this.setState({ [data.name]: data.value });
    }
  };

  handleSettingsOpen = () => {
    const { PID } = this.props.data;

    this.setState({
      settingsOpen: true,
      fanSetPoint: PID[0].SetPoint,
      pumpSetPoint: PID[1].SetPoint,
      fanAlarmHigh: PID[0].Alarm[0].Set,
      pumpAlarmHigh: PID[1].Alarm[0].Set,
      fanAlarmLow: PID[0].Alarm[1].Set,
      pumpAlarmLow: PID[1].Alarm[1].Set,
      fanControlAction: PID[0].ControlAction,
      pumpControlAction: PID[1].ControlAction
    });
  };

  handleSettingsClose = () => this.setState({ settingsOpen: false });

  handleConfigOpen = () => {
    const { title, subtitle, order } = this.props;
    this.setState({
      configOpen: true,
      title: title,
      subtitle: subtitle,
      order: order
    });
  };

  handleConfigClose = () => this.setState({ configOpen: false });

  SuccessMessage = message => ({ closeToast }) => (
    <Message
      success
      icon="check"
      header="Success"
      content={message}
      onDismiss={closeToast}
    />
  );

  ErrorMessage = message => ({ closeToast }) => (
    <Message
      error
      icon="warning circle"
      header="Error"
      content={message}
      onDismiss={closeToast}
    />
  );

  render() {
    const {
      // pumpAlarmHigh,
      // pumpAlarmLow,
      // pumpSetPoint,
      // fanAlarmHigh,
      // fanAlarmLow,
      // fanSetPoint,
      // fanControlAction,
      // pumpControlAction,
      // settingsOpen,
      configOpen,
      title,
      subtitle,
      order
    } = this.state;
    const { pk, sk, channels, onUpdate } = this.props;

    // const settingsUpdateDisabled = !(
    //   pumpAlarmHigh &&
    //   pumpAlarmLow &&
    //   pumpSetPoint &&
    //   fanAlarmHigh &&
    //   fanAlarmLow &&
    //   fanSetPoint
    // );

    const configUpdateDisabled = !(title && order && subtitle);

    return (
      <Card.Content extra>
        {/*<Modal
          open={settingsOpen}
          onClose={this.handleSettingsClose}
          size="mini"
          trigger={
            <Button basic compact onClick={this.handleSettingsOpen}>
              <Icon name="setting" />
              Settings
            </Button>
          }
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Header as="h4">
            <Icon name="settings" />
            <Header.Content>
              Settings
              <Header.Subheader>{sk}</Header.Subheader>
            </Header.Content>
          </Header>
          <Modal.Content>
            <Form>
              <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <Form.Group widths="2">
                  <Form.Input
                    label="Fan Set Point"
                    value={fanSetPoint}
                    name="fanSetPoint"
                    onChange={this.onNameChange}
                  />
                  <Form.Input
                    label="Pump Set Point"
                    value={pumpSetPoint}
                    name="pumpSetPoint"
                    onChange={this.onNameChange}
                  />
                </Form.Group>
                <Form.Group widths="2">
                  <Form.Input
                    label="Alarm High"
                    value={fanAlarmHigh}
                    name="fanAlarmHigh"
                    onChange={this.onNameChange}
                  />
                  <Form.Input
                    label="Alarm High"
                    value={pumpAlarmHigh}
                    name="pumpAlarmHigh"
                    onChange={this.onNameChange}
                  />
                </Form.Group>
                <Form.Group widths="2">
                  <Form.Input
                    label="Alarm Low"
                    value={fanAlarmLow}
                    name="fanAlarmLow"
                    onChange={this.onNameChange}
                  />
                  <Form.Input
                    label="Alarm Low"
                    value={pumpAlarmLow}
                    name="pumpAlarmLow"
                    onChange={this.onNameChange}
                  />
                </Form.Group>
                <Form.Group widths="2">
                  <Form.Checkbox
                    slider
                    label="Control Action"
                    checked={fanControlAction}
                    name="fanControlAction"
                    onChange={() => {
                      this.setState({
                        fanControlAction: !this.state.fanControlAction
                      });
                    }}
                  />
                  <Form.Checkbox
                    slider
                    label="Control Action"
                    checked={pumpControlAction}
                    name="pumpControlAction"
                    onChange={() => {
                      this.setState({
                        pumpControlAction: !this.state.pumpControlAction
                      });
                    }}
                  />
                </Form.Group>
              </Responsive>
              <Responsive {...Responsive.onlyMobile}>
                <Header as="h4">Fan Settings</Header>
                <Form.Input
                  label="Set Point"
                  value={fanSetPoint}
                  name="fanSetPoint"
                  onChange={this.onNameChange}
                />
                <Form.Input
                  label="Alarm High"
                  value={fanAlarmHigh}
                  name="fanAlarmHigh"
                  onChange={this.onNameChange}
                />
                <Form.Input
                  label="Alarm Low"
                  value={fanAlarmLow}
                  name="fanAlarmLow"
                  onChange={this.onNameChange}
                />
                <Form.Checkbox
                  slider
                  label="Fan Control Action"
                  checked={fanControlAction}
                  name="fanControlAction"
                  onChange={() => {
                    this.setState({
                      fanControlAction: !this.state.fanControlAction
                    });
                  }}
                />
                <Header as="h4">Pump Settings</Header>
                <Form.Input
                  label="Set Point"
                  value={pumpSetPoint}
                  name="pumpSetPoint"
                  onChange={this.onNameChange}
                />
                <Form.Input
                  label="Alarm High"
                  value={pumpAlarmHigh}
                  name="pumpAlarmHigh"
                  onChange={this.onNameChange}
                />
                <Form.Input
                  label="Alarm Low"
                  value={pumpAlarmLow}
                  name="pumpAlarmLow"
                  onChange={this.onNameChange}
                />
                <Form.Checkbox
                  slider
                  label="Pump Control Action"
                  checked={pumpControlAction}
                  name="pumpControlAction"
                  onChange={() => {
                    this.setState({
                      pumpControlAction: !this.state.pumpControlAction
                    });
                  }}
                />
                <br />
              </Responsive>
              <Connect mutation={graphqlOperation(mutation.UpdateShadow)}>
                {({ mutation }) => (
                  <Button
                    positive
                    disabled={settingsUpdateDisabled}
                    onClick={async () => {
                      const UserConfig = {
                        PID: [
                          {
                            Alarm: [
                              { Set: round(fanAlarmHigh, 1) },
                              { Set: round(fanAlarmLow, 1) }
                            ],
                            slaveID: 2,
                            SetPoint: round(fanSetPoint, 1),
                            ControlAction: fanControlAction
                          },
                          {
                            Alarm: [
                              { Set: round(pumpAlarmHigh, 1) },
                              { Set: round(pumpAlarmLow, 1) }
                            ],
                            slaveID: 3,
                            SetPoint: round(pumpSetPoint, 1),
                            ControlAction: pumpControlAction
                          }
                        ]
                      };
                      const response = await mutation({
                        thing: sk,
                        groupID: pk,
                        UserConfig: UserConfig
                      });
                      if (response.data && response.data.updateShadow) {
                        toast(this.SuccessMessage("Settings Updated"));
                      } else {
                        toast(this.ErrorMessage("Update Failed"));
                      }
                      this.handleSettingsClose();
                    }}
                  >
                    Update
                  </Button>
                )}
              </Connect>
            </Form>
          </Modal.Content>
                  </Modal>*/}
        <Modal
          open={configOpen}
          onClose={this.handleConfigClose}
          size="mini"
          trigger={
            <Button
              floated="right"
              basic
              compact
              onClick={this.handleConfigOpen}
            >
              <Icon name="configure" />
              Configure
            </Button>
          }
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Header as="h4">
            <Icon name="configure" />
            <Header.Content>
              Configure
              <Header.Subheader>{sk}</Header.Subheader>
            </Header.Content>
          </Header>
          <Modal.Content>
            <Form>
              <Form.Input
                label="Title"
                value={title}
                name="title"
                onChange={this.onConfigChange}
              />
              <Form.Input
                label="Subtitle"
                value={subtitle}
                name="subtitle"
                onChange={this.onConfigChange}
              />
              <Form.Input
                label="Order"
                value={order}
                name="order"
                onChange={this.onOrderChange}
              />
              <Connect mutation={graphqlOperation(mutation.UpdateDevice)}>
                {({ mutation }) => (
                  <Button
                    disabled={configUpdateDisabled}
                    positive
                    onClick={() => {
                      const input = {
                        name: sk,
                        title: title,
                        subtitle: subtitle,
                        order: Number(order),
                        channels: channels
                      };
                      mutation({
                        input: input,
                        groupID: pk
                      })
                        .then(response => {
                          console.log(response);
                          const {
                            name,
                            title,
                            subtitle,
                            order,
                            channels
                          } = response.data.updateDevice;
                          onUpdate(name, title, subtitle, order, channels);
                          toast(this.SuccessMessage("Config Updated"));
                          this.handleConfigClose();
                        })
                        .catch(err => {
                          console.error(err);
                          toast(this.ErrorMessage("Update Failed"));
                          this.handleConfigClose();
                        });
                    }}
                  >
                    Update
                  </Button>
                )}
              </Connect>
            </Form>
          </Modal.Content>
        </Modal>
      </Card.Content>
    );
  }
}
