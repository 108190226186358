import React from "react";
import { Card, Grid, Statistic } from "semantic-ui-react";

const Content = (props) => {
	const {
		Phase: {
			THDI,
			THDV,
			T_active,
			T_apparent,
			T_cosph,
			T_current,
			T_reactive,
			T_voltage,
			active,
			apparent,
			cosph,
			current,
			reactive,
			voltage,
		},
	} = props;
	console.log(props);
	return (
		<Card.Content>
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<Statistic.Group widths="3" size="tiny">
							<Statistic>
								<Statistic.Label>Phase Voltage (V)</Statistic.Label>
								<Statistic.Value>{voltage}</Statistic.Value>
							</Statistic>
							<Statistic>
								<Statistic.Label>Phase Currnet (A)</Statistic.Label>
								<Statistic.Value>{current}</Statistic.Value>
							</Statistic>
							<Statistic>
								<Statistic.Label>Phase CosΦ </Statistic.Label>
								<Statistic.Value>{cosph}</Statistic.Value>
							</Statistic>
						</Statistic.Group>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Statistic.Group widths="3" size="tiny">
							<Statistic>
								<Statistic.Label>Phase KW</Statistic.Label>
								<Statistic.Value>{active}</Statistic.Value>
							</Statistic>
							<Statistic>
								<Statistic.Label>Phase KVAR</Statistic.Label>
								<Statistic.Value>{reactive}</Statistic.Value>
							</Statistic>
							<Statistic>
								<Statistic.Label>Phase KVA</Statistic.Label>
								<Statistic.Value>{apparent}</Statistic.Value>
							</Statistic>
						</Statistic.Group>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Statistic.Group widths="3" size="tiny">
							<Statistic>
								<Statistic.Label>Total Voltage (V)</Statistic.Label>
								<Statistic.Value>{T_voltage}</Statistic.Value>
							</Statistic>
							<Statistic>
								<Statistic.Label>Total Currnet (A)</Statistic.Label>
								<Statistic.Value>{T_current}</Statistic.Value>
							</Statistic>
							<Statistic>
								<Statistic.Label>Total CosΦ </Statistic.Label>
								<Statistic.Value>{T_cosph}</Statistic.Value>
							</Statistic>
						</Statistic.Group>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Statistic.Group widths="3" size="tiny">
							<Statistic>
								<Statistic.Label>Total KW</Statistic.Label>
								<Statistic.Value>{T_active}</Statistic.Value>
							</Statistic>
							<Statistic>
								<Statistic.Label>Total KVAR</Statistic.Label>
								<Statistic.Value>{T_reactive}</Statistic.Value>
							</Statistic>
							<Statistic>
								<Statistic.Label>Total KVA</Statistic.Label>
								<Statistic.Value>{T_apparent}</Statistic.Value>
							</Statistic>
						</Statistic.Group>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Statistic.Group widths="2" size="tiny">
							<Statistic>
								<Statistic.Label>THDV (%)</Statistic.Label>
								<Statistic.Value>{THDV}</Statistic.Value>
							</Statistic>
							<Statistic>
								<Statistic.Label>THDI (%)</Statistic.Label>
								<Statistic.Value>{THDI}</Statistic.Value>
							</Statistic>
						</Statistic.Group>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Card.Content>
	);
};

export default Content;
