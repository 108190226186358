import React from "react";
import { Card, Statistic } from "semantic-ui-react";

export default function DualAIStats(props) {
  const { RH, Temperature, titles } = props;

  RH.color = RH.AlarmHigh ? "red" : RH.AlarmLow ? "blue" : "black";

  Temperature.color = Temperature.AlarmHigh
    ? "red"
    : Temperature.AlarmLow
    ? "blue"
    : "black";

  return (
    <Card.Content>
      <Statistic.Group size="small" widths="2">
        <Statistic color={RH.color}>
          <Statistic.Label>
            {titles[0].name + "(" + titles[0].unit + ")"}
          </Statistic.Label>
          <Statistic.Value>{RH.Value}</Statistic.Value>
        </Statistic>
        <Statistic color={Temperature.color}>
          <Statistic.Label>
            {titles[1].name + "(" + titles[1].unit + ")"}
          </Statistic.Label>
          <Statistic.Value>{Temperature.Value}</Statistic.Value>
        </Statistic>
      </Statistic.Group>
    </Card.Content>
  );
}
