import React, { Component } from "react";
import { Header, Divider, Button, Grid, Container } from "semantic-ui-react";
import GroupList from "./GroupList";
import NewGroup from "./NewGroup";
import SelectedGroup from "./SelectedGroup";

import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from "../../GraphQL/queries";
import MyLoader from "../Base/MyLoader";

export default class GroupScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: null,
      newGroup: false
      // selectedGroup: {
      //   name: "Some Group",
      //   groupid: "Some Group ID",
      //   createdBy: "No One",
      //   createdOn: "Some Date"
      // }
    };
  }

  onNewGroupBackClick = () => {
    this.setState({ newGroup: false });
  };

  onAddGroupClick = () => {
    this.setState({ newGroup: true });
  };

  onSelectedGroupBackClick = () => {
    this.setState({ selectedGroup: null });
  };

  onGroupSelectClick = event => {
    this.setState({ selectedGroup: event });
  };

  render() {
    const { selectedGroup, newGroup } = this.state;

    return selectedGroup ? (
      <React.Fragment>
        <Connect
          query={graphqlOperation(queries.listGroupThingsMap, {
            pk: selectedGroup.sk
          })}
        >
          {({ data }, error, loading) => {
            if (error) {
              return <Header>Error :{error}</Header>;
            }
            if (loading || !data.listGroupThingMap) {
              return <MyLoader />;
            }
            //console.log(data.listGroupThingMap);
            const sorttedData = data.listGroupThingMap.sort((a, b) => {
              // console.log(b.order, a.order);
              return a.order - b.order;
            });
            //console.log(sorttedData);
            return (
              <SelectedGroup
                onBackClick={this.onSelectedGroupBackClick}
                group={selectedGroup}
                items={sorttedData}
                {...this.props}
              />
            );
          }}
        </Connect>
      </React.Fragment>
    ) : newGroup ? (
      <React.Fragment>
        <Container>
          <Grid columns="2">
            <Grid.Column>
              <Header as="h2">New Group</Header>
            </Grid.Column>
            <Grid.Column>
              <Button
                negative
                floated="right"
                compact
                onClick={this.onNewGroupBackClick}
              >
                Back
              </Button>
            </Grid.Column>
          </Grid>
          <Divider />
        </Container>
        <NewGroup goBack={this.onNewGroupBackClick} />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Container>
          <Grid columns="2">
            <Grid.Column>
              <Header as="h2">Groups</Header>
            </Grid.Column>
            <Grid.Column>
              <Button
                positive
                floated="right"
                compact
                onClick={this.onAddGroupClick}
              >
                Add group
              </Button>
            </Grid.Column>
          </Grid>
          <Divider />
          <GroupList selectGroupCallback={this.onGroupSelectClick} />
        </Container>
      </React.Fragment>
    );
  }
}
