import React from "react";
import { SignIn } from "aws-amplify-react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Icon,
  Image
} from "semantic-ui-react";

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);

    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown(e) {
    if (e.keyCode !== 13) return;
    if (this.inputs.username && this.inputs.password) {
      super.signIn(e);
    }
  }
  showComponent(theme) {
    const { theme_color_name } = this.props;
    return (
      <div className="login-form">
        {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
        <style>
          {`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}
        </style>
        <Grid
          textAlign="center"
          style={{ minHeight: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ minWidth: 320, maxWidth: 450 }}>
            <Header as="h2" color={theme_color_name} textAlign="center" icon>
              <Image src={"/favicon.ico"} /> Log-in to your account
            </Header>
            <Form size="large">
              <Segment raised>
                <Form.Input
                  id="username"
                  key="username"
                  name="username"
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail address"
                  onChange={this.handleInputChange}
                />
                <Form.Input
                  id="password"
                  key="password"
                  name="password"
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  onChange={this.handleInputChange}
                />

                <Button
                  color={theme_color_name}
                  fluid
                  size="large"
                  onClick={event =>
                    this.inputs.username && this.inputs.password
                      ? super.signIn(event)
                      : null
                  }
                >
                  Login
                </Button>
              </Segment>
            </Form>
            <Message attached="bottom">
              <Button.Group fluid widths="2" size="small">
                <Button
                  compact
                  basic
                  onClick={() => super.changeState("forgotPassword")}
                >
                  <Icon name="question circle" />
                  Forgot Password
                </Button>
                <Button
                  compact
                  basic
                  onClick={() => super.changeState("signUp")}
                >
                  <Icon name="signup" />
                  New User
                </Button>
              </Button.Group>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default CustomSignIn;
