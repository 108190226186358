import React from "react";
import { Statistic, Label } from "semantic-ui-react";

export default function ContentPID(props) {
  const { Value, SetPoint, titles, Alarm, Connected, ControlAction } = props;
  //console.log(props);

  var ValColor = "black";

  Alarm.map(alarm => {
    if (alarm.Status) {
      if (alarm.Type === "HIGH") return (ValColor = "red");
      if (alarm.Type === "LOW") return (ValColor = "blue");
      return null;
    }
    return null;
  });

  ValColor = Connected ? ValColor : "brown";

  return (
    <React.Fragment>
      <Statistic color={ValColor}>
        <Statistic.Label>
          {titles.name + " (" + titles.unit + ")"}
        </Statistic.Label>
        <Statistic.Value>{Connected ? Value : "N/C"}</Statistic.Value>
        <Label basic size="tiny" color="grey">
          Set Point: {ControlAction ? SetPoint : "OFF"}
        </Label>
      </Statistic>
    </React.Fragment>
  );
}
