import React from "react";
import { Line } from "react-chartjs-2";
export default function ReportGraph(props) {
  const { data, device } = props;

  const myOptions = {
    scales: {
      xAxes: [
        {
          type: "time",
          distribution: "linear",
          time: {
            minUnit: "minute",
            round: "minute",
            displayFormats: {
              minute: "HH:mm",
              hour: "MMM D \n HH:mm"
            }
          }
        }
      ]
    },
    elements: {
      line: {
        tension: 0 // disables bezier curves
      }
    }
  };

  const myData = {
    labels: data.map(item => item.sk),
    datasets: [
      {
        label: device.channels[0].name + " (" + device.channels[0].unit + ")",
        fill: false,
        borderColor: "#FF6384",
        backgroundColor: "#FF6384",
        data: data.map(item =>
          item.payload.data.Connected
            ? item.payload.data.Temperature.Value
            : NaN
        )
      },
      {
        label: device.channels[1].name + " (" + device.channels[1].unit + ")",
        fill: false,
        borderColor: "#4BC0C0",
        backgroundColor: "#4BC0C0",
        data: data.map(item =>
          item.payload.data.Connected ? item.payload.data.RH.Value : NaN
        )
      },
      {
        label: device.channels[2].name + " (" + device.channels[2].unit + ")",
        fill: false,
        borderColor: "#36A2EB",
        backgroundColor: "#36A2EB",
        data: data.map(item =>
          item.payload.data.Connected ? item.payload.data.DP.Value : NaN
        )
      }
    ]
  };

  return (
    <React.Fragment>
      <Line data={myData} options={myOptions} />
    </React.Fragment>
  );
}
