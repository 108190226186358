import React, { Component } from "react";
import {
  Container,
  Header,
  Form,
  Button,
  Message,
  Divider,
  Segment,
  Icon,
  Confirm
} from "semantic-ui-react";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as mutation from "../../GraphQL/mutations";
import { toast } from "react-toastify";
export default class GroupSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name
    };
  }

  handleNameChange = (event, data) => {
    if (data.value.length < 25) {
      this.setState({ name: data.value });
    }
  };

  SuccessMessage = ({ closeToast }) => (
    <Message
      success
      icon="check"
      header="Success"
      content="Group Updated"
      onDismiss={closeToast}
    />
  );

  ErrorMessage = message => ({ closeToast }) => (
    <Message
      error
      icon="warning circle"
      header="Error"
      content={message}
      onDismiss={closeToast}
    />
  );

  DeleteMessage = ({ closeToast }) => (
    <Message
      warning
      icon="trash"
      header="Delete"
      content="Device delete from Group"
      onDismiss={closeToast}
    />
  );

  GroupDeleteMessage = ({ closeToast }) => (
    <Message
      warning
      icon="trash"
      header="Delete"
      content="Group Deleted"
      onDismiss={closeToast}
    />
  );

  render() {
    const { name } = this.state;
    const { sk, onUpdate, items, role, removeDevice } = this.props;
    return (
      <React.Fragment>
        <Container style={{ width: "320px" }}>
          <Header>Group Settings</Header>
          <Divider />
          <Form>
            <Form.Input
              name="Group Name"
              label="Group Name"
              value={name}
              onChange={this.handleNameChange}
            />
            <Connect mutation={graphqlOperation(mutation.UpdateGroup)}>
              {({ mutation }) => (
                <Button
                  positive
                  disabled={!name}
                  onClick={() => {
                    mutation({ groupID: sk, name: name })
                      .then(() => {
                        toast(this.SuccessMessage);
                        onUpdate();
                      })
                      .catch(e => {
                        toast(this.ErrorMessage(e));
                        console.log(e);
                      });
                  }}
                >
                  Update
                </Button>
              )}
            </Connect>
          </Form>
          <Divider />
          {Object.keys(items).map(key => (
            <React.Fragment key={key}>
              {this.state[key] === "delete" ? (
                <Segment>
                  <Header>
                    Confirm Delete ?
                    <Header.Subheader>
                      <span style={{ color: "red" }}>
                        {items[key].device.title}
                      </span>{" "}
                      ({key}) will be removed from this group
                    </Header.Subheader>
                  </Header>
                  <br />
                  <Connect mutation={graphqlOperation(mutation.delDevice)}>
                    {({ mutation }) => (
                      <Button
                        positive
                        onClick={() => {
                          mutation({
                            thing: items[key].device.sk,
                            groupID: items[key].device.pk
                          })
                            .then(() => {
                              toast(this.DeleteMessage);
                              removeDevice(key);
                            })
                            .catch(err => {
                              toast(this.ErrorMessage(err));
                              console.log(err);
                            });
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </Connect>
                  <Button
                    negative
                    onClick={() => {
                      this.setState({ [key]: null });
                    }}
                  >
                    Cancel
                  </Button>
                </Segment>
              ) : (
                <Segment clearing>
                  <Header floated="left">
                    {items[key].device.title}
                    <Header.Subheader>
                      {items[key].device.subtitle}
                    </Header.Subheader>
                    <Header.Subheader>{items[key].device.sk}</Header.Subheader>
                  </Header>

                  <Icon
                    name="trash"
                    color="red"
                    style={{ float: "right" }}
                    disabled={role < 100}
                    onClick={() => {
                      this.setState({ [key]: "delete" });
                    }}
                  />
                </Segment>
              )}
            </React.Fragment>
          ))}
          <Button
            negative
            disabled={role < 100}
            onClick={() => {
              this.setState({ deleteGroup: true });
            }}
          >
            Delete Group
          </Button>
          <Connect mutation={graphqlOperation(mutation.delGroup)}>
            {({ mutation }) => (
              <Confirm
                header="Confirm Delete Group ?"
                content="This group will be permanently delete. All users will loose access to devices added in this group"
                confirmButton="Delete Group"
                open={this.state.deleteGroup}
                onCancel={() => {
                  this.setState({ deleteGroup: false });
                }}
                onConfirm={() => {
                  mutation({ groupID: sk })
                    .then(() => {
                      toast(this.GroupDeleteMessage);
                      onUpdate();
                    })
                    .catch(err => {
                      toast(this.ErrorMessage(err));
                      console.log(err);
                    });
                }}
              />
            )}
          </Connect>
        </Container>
      </React.Fragment>
    );
  }
}
