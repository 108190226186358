import React, { Component } from "react";
import { Form, Container, Header, Icon } from "semantic-ui-react";

export default class NewDevice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      srno: "",
      secret: "",
      title: "",
      subtitle: "",
      order: "1"
    };
  }

  onNameChange = (event, data) => {
    this.setState({ [data.name]: data.value });
  };

  render() {
    const { srno, secret, title, subtitle, order } = this.state;
    return (
      <React.Fragment>
        <Container style={{ width: 320 }}>
          <Header as="h3">
            <Icon name="plus" />
            Add New Device
          </Header>
          <Form>
            <Form.Group widths={2}>
              <Form.Input
                label="Serial No."
                value={srno}
                name="srno"
                onChange={this.onNameChange}
                required
              />
              <Form.Input
                label="Secret"
                value={secret}
                name="secret"
                onChange={this.onNameChange}
                required
              />
            </Form.Group>
            <Form.Input
              label="Title"
              value={title}
              name="title"
              onChange={this.onNameChange}
              required
            />
            <Form.Input
              label="Subtitle"
              value={subtitle}
              name="subtitle"
              onChange={this.onNameChange}
            />
            <Form.Input
              label="Display Order"
              value={order}
              name="order"
              onChange={this.onNameChange}
              required
            />
            <Form.Button
              positive
              inline
              disabled={this.props.awaitAdd}
              loading={this.props.awaitAdd}
              onClick={() => {
                const input = {
                  name: srno,
                  title: title,
                  subtitle: subtitle,
                  order: order
                };
                this.props.addDevice(input, secret, this.props.groupID);
              }}
            >
              Add Device
            </Form.Button>
          </Form>
        </Container>
      </React.Fragment>
    );
  }
}
