import React, { Component } from "react";
import { Menu, Image } from "semantic-ui-react";
import GroupScreen from "../Groups/GroupScreen";
import CustomSignOut from "../Auth/SignOut";

import PubSub from "@aws-amplify/pubsub";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

PubSub.configure();
PubSub.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "ap-south-1",
    aws_pubsub_endpoint:
      "wss://a2kdzrq7yvunkn-ats.iot.ap-south-1.amazonaws.com/mqtt"
  })
);

export default class MainScreen extends Component {
  constructor(props) {
    super(props);

    this.state = { dark: false };
  }

  componentDidMount() {
    fetch("/manifest.json")
      .then(res => res.json())
      .then(res => {
        this.setState({ header: res.short_name, theme_dark: res.theme_dark });
      })
      .catch(err => console.log(err));
  }

  render() {
    const { header, theme_dark } = this.state;
    return (
      <React.Fragment>
        <Menu fixed="top" inverted={theme_dark} borderless>
          <Menu.Item>
            <Image
              size="mini"
              src={"/favicon.ico"}
              style={{ marginRight: "0.5em" }}
            />
          </Menu.Item>
          <Menu.Item header as="h4">
            <b>{header}</b>
          </Menu.Item>
          <Menu.Item position="right">v 1.7.0</Menu.Item>
          <CustomSignOut />
        </Menu>
        <div className="base-screen" style={{ paddingTop: "6rem" }}>
          <GroupScreen theme_dark={theme_dark} />
        </div>
        <ToastContainer
          className="toast-cont-bg"
          position="bottom-left"
          autoClose={10000}
          hideProgressBar
          closeButton={false}
          newestOnTop
        />
      </React.Fragment>
    );
  }
}
