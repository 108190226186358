import React from "react";
import { Statistic, Label } from "semantic-ui-react";

export default function ContentDPI(props) {
  const { RPM, MPM, titles, Connected } = props;
  return (
    <React.Fragment>
      <Statistic>
        <Statistic.Label>
          {titles.name + " (" + titles.unit + ")"}
        </Statistic.Label>
        <Statistic.Value>{Connected ? MPM : "N/C"}</Statistic.Value>
        <Label basic size="tiny" color="purple" circular>
          RPM: {Connected ? RPM : "N/C"}
        </Label>
      </Statistic>
    </React.Fragment>
  );
}
