import React from "react";
import { Statistic } from "semantic-ui-react";
import Channel from "./Channel";

export default function ChannelGroup(props) {
  const { Channel1, Channel2, title1, title2, Connected } = props;
  return (
    <Statistic.Group size="small" widths="2">
      <Channel {...Channel1} {...title1} Connected={Connected} />
      <Channel {...Channel2} {...title2} Connected={Connected} />
    </Statistic.Group>
  );
}
