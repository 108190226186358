import React from "react";
import { Table } from "semantic-ui-react";

export default function ReportTable(props) {
  const {  data } = props;
  console.log(data);
  return (
    <React.Fragment>
      <Table unstackable compact celled textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="2">Timestamp</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Humidity (%)</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">Temperature (°C)</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>PV</Table.HeaderCell>
            <Table.HeaderCell>SV</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body
          children={data.map(item => (
            <Table.Row key={item.sk}>
              <Table.Cell>{item.sk}</Table.Cell>
              <Table.Cell
                positive={item.payload.data.RH.AlarmLow}
                negative={item.payload.data.RH.AlarmHigh}
              >
                {item.payload.data.RH.Value}
              </Table.Cell>
              <Table.Cell>{item.payload.data.RH.SetPoint}</Table.Cell>
              <Table.Cell>{item.payload.data.Temperature.Value}</Table.Cell>
            </Table.Row>
          ))}
        />
      </Table>
    </React.Fragment>
  );
}
