import React from "react";
import { Line } from "react-chartjs-2";
export default function ReportGraph(props) {
	const { data, device } = props;

	const myOptions = {
		scales: {
			xAxes: [
				{
					type: "time",
					distribution: "linear",
					time: {
						minUnit: "minute",
						round: "minute",
						displayFormats: {
							minute: "HH:mm",
							hour: "MMM D \n HH:mm"
						},
						scaleLabel: {
							display: true,
							labelString: "Date / Time"
						}
					}
				}
			],
			yAxes: [
				{
					id: "left-y-axis",
					type: "linear",
					position: "left",
					scaleLabel: {
						display: true,
						labelString: "Temperature / Speed"
					}
				},
				{
					id: "right-y-axis",
					type: "linear",
					position: "right",
					scaleLabel: {
						display: true,
						labelString: "Current"
					}
				}
			]
		},
		elements: {
			line: {
				tension: 0 // disables bezier curves
			}
		}
	};
	const charColor = [
		"#ffce56",
		"#ff63846f",
		"#ff6384",
		"#36a2eb6f",
		"#36a2eb",
		"#4bc0c06f",
		"#4bc0c0"
	];

	const myData = {
		labels: data.map(item => item.sk),
		datasets: device.channels.map((item, index) => {
			return {
				label: item.name + " (" + item.unit + ")",
				fill: false,
				pointRadius: 3,
				pointStyle: index % 2 > 0 ? 'cross' : 'circle',
				yAxisID: index % 2 > 0 ? "right-y-axis" : "left-y-axis",
				borderColor: charColor[index],
				backgroundColor: charColor[index],
				data: data.map(item => {
					if (index === 0) {
						const { MPM, Connected } = item.payload.data.MPM[index];
						if (Connected) {
							return MPM;
						} else {
							return Number.NaN;
						}
					} else {
						const { Value, Connected } = item.payload.data.PID[index - 1];
						if (Connected) {
							return Value;
						} else {
							return Number.NaN;
						}
					}
				})
			};
		})
	};

	return (
		<React.Fragment>
			<Line data={myData} options={myOptions} />
		</React.Fragment>
	);
}
