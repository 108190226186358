import React from "react";
import { Table } from "semantic-ui-react";
import Cell from "./Cell";

export default function ReportTable(props) {
  const { device, data } = props;
  console.log(data);
  return (
    <React.Fragment>
      <Table unstackable compact celled textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            {device.channels.map((item, index) => {
              return (
                <Table.HeaderCell key={index}>
                  {item.name + " (" + item.unit + ")"}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body
          children={data.map(item => {
            const {
              Channel1,
              Channel2,
              Channel3,
              Channel4,
              Channel5,
              Channel6,
              Channel7,
              Channel8,
              Connected
            } = item.payload.data;
            return (
              <Table.Row key={item.sk}>
                <Table.Cell>{item.sk}</Table.Cell>
                <Cell {...Channel1} Connected={Connected} />
                <Cell {...Channel2} Connected={Connected} />
                <Cell {...Channel3} Connected={Connected} />
                <Cell {...Channel4} Connected={Connected} />
                <Cell {...Channel5} Connected={Connected} />
                <Cell {...Channel6} Connected={Connected} />
                <Cell {...Channel7} Connected={Connected} />
                <Cell {...Channel8} Connected={Connected} />
              </Table.Row>
            );
          })}
        />
      </Table>
    </React.Fragment>
  );
}
