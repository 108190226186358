import React from "react";
import { Card, Statistic } from "semantic-ui-react";

export default function ContentTHP(props) {
  const { TEMP, RH, Connected, titles } = props;

  return (
    <React.Fragment>
      <Card.Content>
        <Statistic.Group widths="2" size="small">
          <Statistic color="olive">
            <Statistic.Label>
              {titles[0].name + " (" + titles[0].unit + ")"}
            </Statistic.Label>
            <Statistic.Value>{Connected ? RH : "N/C"}</Statistic.Value>
          </Statistic>
          <Statistic color="orange">
            <Statistic.Label>
              {titles[1].name + " (" + titles[1].unit + ")"}
            </Statistic.Label>
            <Statistic.Value>{Connected ? TEMP : "N/C"}</Statistic.Value>
          </Statistic>
        </Statistic.Group>
      </Card.Content>
    </React.Fragment>
  );
}
