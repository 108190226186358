import React, { Component } from "react";
import {
  Card,
  Button,
  Icon,
  Modal,
  Header,
  Form,
  Message,
  Responsive
} from "semantic-ui-react";
import { round } from "../../Base/Helper";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as mutation from "../../../GraphQL/mutations";
import { toast } from "react-toastify";

export default class Actions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settingsOpen: false,
      Channel1: {
        SetPoint: null,
        AlarmHighSP: null,
        AlarmLowSP: null
      },
      Channel2: {
        SetPoint: null,
        AlarmHighSP: null,
        AlarmLowSP: null
      },
      channels: []
    };
  }

  handleSettingsClose = () => this.setState({ settingsOpen: false });

  handleSettingsOpen = () => {
    console.log(this.props);
    const { Channel1, Channel2 } = this.props.data;
    this.setState({
      settingsOpen: true,
      Channel1: Channel1,
      Channel2: Channel2
    });
  };

  onSetChange = (event, data) => {
    if (
      (!Number.isNaN(data.value) &&
        data.value >= data.minimum &&
        data.value <= data.maximum) ||
      data.value === "" ||
      data.value === "-"
    )
      this.setState({
        [data.channel]: {
          ...this.state[data.channel],
          [data.name]: data.value
        }
      });
  };

  onConfigChange = (event, data) => {
    if (data.value.length < 20) {
      this.setState({ [data.name]: data.value });
    }
  };

  onOrderChange = (event, data) => {
    if (
      (Number.isInteger(Number(data.value)) &&
        data.value > 0 &&
        data.value <= 50) ||
      data.value === ""
    ) {
      this.setState({ [data.name]: data.value });
    }
  };

  handleChannelChange = (event, data) => {
    this.setState(state => {
      const channels = state.channels.map((item, index) => {
        const newItem = { ...item };
        if (data.index === index) {
          newItem[data.name] = data.value;
        }
        return newItem;
      });
      return { channels };
    });
  };

  handleConfigOpen = () => {
    const { title, subtitle, order, channels } = this.props;
    this.setState({
      configOpen: true,
      title: title,
      subtitle: subtitle,
      order: order,
      channels: channels
    });
  };

  handleConfigClose = () => this.setState({ configOpen: false });

  SuccessMessage = message => ({ closeToast }) => (
    <Message
      success
      icon="check"
      header="Success"
      content={message}
      onDismiss={closeToast}
    />
  );

  ErrorMessage = message => ({ closeToast }) => (
    <Message
      error
      icon="warning circle"
      header="Error"
      content={message}
      onDismiss={closeToast}
    />
  );

  render() {
    const {
      settingsOpen,
      configOpen,
      title,
      subtitle,
      order,
      channels,
      Channel1,
      Channel2
    } = this.state;
    const { pk, sk, onUpdate } = this.props;

    const propChannel = this.props.channels;

    const settingsUpdateDisabled = !(
      Channel1.AlarmHighSP &&
      Channel1.AlarmLowSP &&
      Channel1.SetPoint &&
      Channel2.AlarmHighSP &&
      Channel2.AlarmLowSP &&
      Channel2.SetPoint
    );
    const configUpdateDisabled = !(title && order && subtitle);

    return (
      <Card.Content extra>
        <Modal
          open={settingsOpen}
          onClose={this.handleSettingsClose}
          size="mini"
          trigger={
            <Button basic compact onClick={this.handleSettingsOpen}>
              <Icon name="setting" />
              Settings
            </Button>
          }
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Header as="h4">
            <Icon name="settings" />
            <Header.Content>
              Settings
              <Header.Subheader>{sk}</Header.Subheader>
            </Header.Content>
          </Header>
          <Modal.Content>
            <Form>
              <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <Form.Group widths="2">
                  <Form.Input
                    label={
                      propChannel[0].name +
                      " (" +
                      propChannel[0].unit +
                      ") Set Point"
                    }
                    value={Channel1.SetPoint}
                    channel="Channel1"
                    name="SetPoint"
                    minimum={-100}
                    maximum={100}
                    onChange={this.onSetChange}
                  />
                  <Form.Input
                    label={
                      propChannel[1].name +
                      " (" +
                      propChannel[1].unit +
                      ") Set Point"
                    }
                    value={Channel2.SetPoint}
                    channel="Channel2"
                    name="SetPoint"
                    minimum={-100}
                    maximum={100}
                    onChange={this.onSetChange}
                  />
                </Form.Group>
                <Form.Group widths="2">
                  <Form.Input
                    label={"Alarm High Set Point"}
                    value={Channel1.AlarmHighSP}
                    channel="Channel1"
                    name="AlarmHighSP"
                    minimum={0}
                    maximum={50}
                    onChange={this.onSetChange}
                  />
                  <Form.Input
                    label={"Alarm High Set Point"}
                    value={Channel2.AlarmHighSP}
                    channel="Channel2"
                    name="AlarmHighSP"
                    minimum={0}
                    maximum={50}
                    onChange={this.onSetChange}
                  />
                </Form.Group>
                <Form.Group widths="2">
                  <Form.Input
                    label={"Alarm Low Set Point"}
                    value={Channel1.AlarmLowSP}
                    channel="Channel1"
                    name="AlarmLowSP"
                    minimum={0}
                    maximum={50}
                    onChange={this.onSetChange}
                  />
                  <Form.Input
                    label={"Alarm Low Set Point"}
                    value={Channel2.AlarmLowSP}
                    channel="Channel2"
                    name="AlarmLowSP"
                    minimum={0}
                    maximum={50}
                    onChange={this.onSetChange}
                  />
                </Form.Group>
              </Responsive>
              <Responsive {...Responsive.onlyMobile}>
                <Header as="h4">
                  {propChannel[0].name + " (" + propChannel[0].unit + ")"}
                </Header>
                <Form.Input
                  label="Set Point"
                  value={Channel1.SetPoint}
                  channel="Channel1"
                  name="SetPoint"
                  minimum={-100}
                  maximum={100}
                  onChange={this.onSetChange}
                />
                <Form.Input
                  label={"Alarm High Set Point"}
                  value={Channel1.AlarmHighSP}
                  channel="Channel1"
                  name="AlarmHighSP"
                  minimum={0}
                  maximum={50}
                  onChange={this.onSetChange}
                />
                <Form.Input
                  label={"Alarm Low Set Point"}
                  value={Channel1.AlarmLowSP}
                  channel="Channel1"
                  name="AlarmLowSP"
                  minimum={0}
                  maximum={50}
                  onChange={this.onSetChange}
                />

                <Header as="h4">
                  {propChannel[1].name + " (" + propChannel[1].unit + ")"}
                </Header>
                <Form.Input
                  label="Set Point"
                  value={Channel2.SetPoint}
                  channel="Channel2"
                  name="SetPoint"
                  minimum={-100}
                  maximum={100}
                  onChange={this.onSetChange}
                />
                <Form.Input
                  label={"Alarm High Set Point"}
                  value={Channel2.AlarmHighSP}
                  channel="Channel2"
                  name="AlarmHighSP"
                  minimum={0}
                  maximum={50}
                  onChange={this.onSetChange}
                />
                <Form.Input
                  label={"Alarm Low Set Point"}
                  value={Channel2.AlarmLowSP}
                  channel="Channel2"
                  name="AlarmLowSP"
                  minimum={0}
                  maximum={50}
                  onChange={this.onSetChange}
                />
                <br />
              </Responsive>
              <Connect mutation={graphqlOperation(mutation.UpdateShadow)}>
                {({ mutation }) => (
                  <Button
                    positive
                    disabled={settingsUpdateDisabled}
                    onClick={async () => {
                      const UserConfig = {
                        Channel1: {
                          SetPoint: round(Channel1.SetPoint, 1),
                          AlarmHighSP: round(Channel1.AlarmHighSP, 1),
                          AlarmLowSP: round(Channel1.AlarmLowSP, 1)
                        },
                        Channel2: {
                          SetPoint: round(Channel2.SetPoint, 1),
                          AlarmHighSP: round(Channel2.AlarmHighSP, 1),
                          AlarmLowSP: round(Channel2.AlarmLowSP, 1)
                        }
                      };
                      const response = await mutation({
                        thing: sk,
                        groupID: pk,
                        UserConfig: UserConfig
                      });
                      if (response.data && response.data.updateShadow) {
                        toast(this.SuccessMessage("Settings Updated"));
                      } else {
                        toast(this.ErrorMessage("Update Failed"));
                      }
                      this.handleSettingsClose();
                    }}
                  >
                    Update
                  </Button>
                )}
              </Connect>
            </Form>
          </Modal.Content>
        </Modal>

        <Modal
          open={configOpen}
          onClose={this.handleConfigClose}
          size="mini"
          trigger={
            <Button
              floated="right"
              basic
              compact
              onClick={this.handleConfigOpen}
            >
              <Icon name="configure" />
              Configure
            </Button>
          }
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Header as="h4">
            <Icon name="configure" />
            <Header.Content>
              Configure
              <Header.Subheader>{sk}</Header.Subheader>
            </Header.Content>
          </Header>
          <Modal.Content>
            <Form>
              <Form.Input
                label="Title"
                value={title}
                name="title"
                onChange={this.onConfigChange}
              />
              <Form.Input
                label="Subtitle"
                value={subtitle}
                name="subtitle"
                onChange={this.onConfigChange}
              />
              <Form.Input
                label="Order"
                value={order}
                name="order"
                onChange={this.onOrderChange}
              />
              <Header>Channel Headers</Header>
              {channels.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Form.Group widths={2} unstackable>
                      <Form.Input
                        label="Name"
                        value={item.name}
                        index={index}
                        name="name"
                        onChange={this.handleChannelChange}
                      />
                      <Form.Input
                        label="Unit"
                        value={item.unit}
                        index={index}
                        name="unit"
                        onChange={this.handleChannelChange}
                      />
                    </Form.Group>
                  </React.Fragment>
                );
              })}
              <Connect mutation={graphqlOperation(mutation.UpdateDevice)}>
                {({ mutation }) => (
                  <Button
                    disabled={configUpdateDisabled}
                    positive
                    onClick={() => {
                      const input = {
                        name: sk,
                        title: title,
                        subtitle: subtitle,
                        order: Number(order),
                        channels: channels
                      };
                      mutation({
                        input: input,
                        groupID: pk
                      })
                        .then(response => {
                          console.log(response);
                          const {
                            name,
                            title,
                            subtitle,
                            order,
                            channels
                          } = response.data.updateDevice;
                          onUpdate(name, title, subtitle, order, channels);
                          toast(this.SuccessMessage("Config Updated"));
                          this.handleConfigClose();
                        })
                        .catch(err => {
                          console.error(err);
                          toast(this.ErrorMessage("Update Failed"));
                          this.handleConfigClose();
                        });
                    }}
                  >
                    Update
                  </Button>
                )}
              </Connect>
            </Form>
          </Modal.Content>
        </Modal>
      </Card.Content>
    );
  }
}
