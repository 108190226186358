import React, { Component } from "react";
import { Form, Header, Dropdown, Button } from "semantic-ui-react";

import { DateTimeInput } from "semantic-ui-calendar-react";
import BaseReport from "./BaseReport";
export default class DataScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDevice: null,
      startDate: "",
      endDate: "",
      goDisable: true,
      SubmitEndDate: "",
      SubmitStartDate: "",
      SubmitSelectedDevice: null
    };
  }

  handleSelectChange = (e, { value }) => {
    this.setState(this.setState({ selectedDevice: value }));
  };

  handleDateChange = (event, { name, value }) => {
    let goDisable = true;
    if (name === "endDate") {
      if (this.state.startDate < value && this.state.selectedDevice) {
        goDisable = false;
      }
    } else {
      if (this.state.endDate > value && this.state.selectedDevice) {
        goDisable = false;
      }
    }
    this.setState({ [name]: value, goDisable: goDisable });
  };

  handleGoClick = () => {
    const { selectedDevice, startDate, endDate } = this.state;
    this.setState({
      SubmitEndDate: endDate,
      SubmitStartDate: startDate,
      SubmitSelectedDevice: selectedDevice
    });
  };

  render() {
    // console.log(this.props);
    const { items } = this.props;

    const {
      selectedDevice,
      startDate,
      endDate,
      goDisable,
      SubmitStartDate,
      SubmitEndDate,
      SubmitSelectedDevice
    } = this.state;

    var options = [];
    Object.keys(items).map(key => {
      const { title, subtitle, order } = items[key].device;
      let obj = {
        key: key,
        value: key,
        text: title,
        content: <Header as="h4" content={title} subheader={subtitle} />,
        order: order
      };
      return options.push(obj);
    });

    options = options.sort((a, b) => {
      // console.log(b.order, a.order);
      return a.order - b.order;
    });
    return (
      <React.Fragment>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              label="Select Device"
              control={Dropdown}
              fluid
              placeholder="Select Device"
              search
              selection
              clearable
              scrolling
              options={options}
              value={selectedDevice}
              onChange={this.handleSelectChange}
            />
            <DateTimeInput
              popupPosition="bottom center"
              startMode="day"
              closable
              divider=" "
              label="Start Date / Time"
              name="startDate"
              dateTimeFormat="YYYY-MM-DD HH:mm:ss"
              autoComplete="off"
              animation="none"
              value={startDate}
              onChange={this.handleDateChange}
              hideMobileKeyboard
            />
            <DateTimeInput
              popupPosition="bottom center"
              startMode="day"
              closable
              divider=" "
              label="End Date / Time"
              name="endDate"
              dateTimeFormat="YYYY-MM-DD HH:mm:ss"
              autoComplete="off"
              animation="none"
              value={endDate}
              onChange={this.handleDateChange}
              hideMobileKeyboard
            />
          </Form.Group>
          <Form.Field
            control={Button}
            positive
            content="Go"
            disabled={goDisable}
            onClick={this.handleGoClick}
          />
        </Form>
        <BaseReport
          selectedDevice={SubmitSelectedDevice}
          startDate={SubmitStartDate}
          endDate={SubmitEndDate}
          device={
            SubmitSelectedDevice ? items[SubmitSelectedDevice].device : null
          }
        />
      </React.Fragment>
    );
  }
}
