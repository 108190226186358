import React from "react";
import { Card, Icon, Label } from "semantic-ui-react";
export default function DualAINotifBar(props) {
  const { Temperature, RH, DI1, DI2 } = props;

  const faultStatus =
    Temperature.AlarmHigh ||
    Temperature.AlarmLow ||
    RH.AlarmHigh ||
    RH.AlarmLow
      ? true
      : false;

  const errorStatus =
    (isNaN(Temperature.Value) && Temperature.Value !== "OFF") ||
    (isNaN(RH.Value) && RH.Value !== "OFF")
      ? true
      : false;

  return (
    <Card.Content extra>
      {faultStatus ? (
        <Icon
          name="warning sign"
          color="orange"
          size="large"
          style={{ float: "right" }}
        />
      ) : null}
      {errorStatus ? (
        <Icon
          name="warning circle"
          color="red"
          size="large"
          style={{ float: "right" }}
        />
      ) : null}
      {faultStatus || errorStatus ? null : (
        <Icon name="heart" color="red" size="large" style={{ float: "left" }} />
      )}
      {DI1 ? (
        <Label color="violet" circular>
          <Icon name="alarm" />1
        </Label>
      ) : null}
      {DI2 ? (
        <Label color="purple" circular>
          <Icon name="alarm" />2
        </Label>
      ) : null}
    </Card.Content>
  );
}
