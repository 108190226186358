import React from "react";
import { Card, Icon } from "semantic-ui-react";
export default function DualAINotifBar(props) {
  const { PID, THP } = props;
  const { TEMP, RH } = THP[0];
  let faultStatus = false;

  PID.map(item => {
    return item.Alarm.map(alarm => {
      if (alarm.Status) {
        return (faultStatus = true);
      }
      return null;
    });
  });

  const errorStatus =
    (isNaN(RH) && RH !== "OFF") || (isNaN(TEMP) && TEMP !== "OFF")
      ? true
      : false;

  return (
    <Card.Content extra>
      {faultStatus ? (
        <Icon
          name="warning sign"
          color="orange"
          size="large"
          style={{ float: "right" }}
        />
      ) : null}
      {errorStatus ? (
        <Icon
          name="warning circle"
          color="red"
          size="large"
          style={{ float: "right" }}
        />
      ) : null}
      {faultStatus || errorStatus ? null : (
        <Icon name="heart" color="red" size="large" style={{ float: "left" }} />
      )}
    </Card.Content>
  );
}
