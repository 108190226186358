import React from "react";
import DualAICard from "./gui-DualAI/Card";
import JLCGEMCard from "./gui-JLCGEM/Card";
import JLCSAFCard from "./gui-JLCSAF/Card";
import Manvi01Card from "./gui-Manvi01/Card";
import Manvi02Card from "./gui-Manvi02/Card";
import MultiPIDCard from "./gui-MultiPID/Card";
import MultiChannelCard from "./gui-MultiChannel/Card";
import THPCard from "./gui-THP/Card";
import HTI3000 from "./gui-HTI3000/Card";
import Clariant01Card from "./gui-Clariant01/Card";
export default function BaseCard(props) {
	var cardToRender;
	console.log(props.card.guiID);
	switch (props.card.guiID) {
		case "DualAI":
			cardToRender = <DualAICard {...props} />;
			break;
		case "JLCGEM":
			cardToRender = <JLCGEMCard {...props} />;
			break;
		case "JLCSAF":
			cardToRender = <JLCSAFCard {...props} />;
			break;
		case "Manvi01":
			cardToRender = <Manvi01Card {...props} />;
			break;
		case "Manvi02":
			cardToRender = <Manvi02Card {...props} />;
			break;
		case "MultiPID":
			cardToRender = <MultiPIDCard {...props} />;
			break;
		case "MultiChannel":
			cardToRender = <MultiChannelCard {...props} />;
			break;
		case "THP":
			cardToRender = <THPCard {...props}></THPCard>;
			break;
		case "HTI3000":
			cardToRender = <HTI3000 {...props}></HTI3000>;
			break;
		case "Clariant01":
			cardToRender = <Clariant01Card {...props}></Clariant01Card>;
			break;
		default:
			console.log("No Card Found");
			cardToRender = null;
	}
	return <React.Fragment>{cardToRender}</React.Fragment>;
}
