import React from "react";
import NotifBar from "./NotifBar";
import CardHeaders from "../CardHeaders";
import { Card, Image } from "semantic-ui-react";
import Content from "./Content";
import Extra from "./Extra";
import Actions from "./Actions";

const Clariant01Card = (props) => {
	const { card, shadow, role, onUpdate } = props;

	return (
		<React.Fragment>
			<Card raised style={{ minWidth: 450, minHeight: 320 }}>
				<Card.Content textAlign="center">
					<Image src={"./clariant-logo.jpg"} size="medium" />
				</Card.Content>
				<NotifBar {...shadow.data} />
				<CardHeaders {...card} updatedOn={new Date(shadow.Timestamp)} />
				<Content {...shadow.data}></Content>
				<Extra {...shadow.data}></Extra>
				{role >= 75 ? (
					<Actions {...shadow} {...card} onUpdate={onUpdate} />
				) : null}
			</Card>
		</React.Fragment>
	);
};

export default Clariant01Card;
