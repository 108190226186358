import React from "react";
import { Card, Statistic } from "semantic-ui-react";
import THPChannel from "./THPChannel";

export default function Content(props) {
  console.log(props);
  const { Temperature, RH, DP, Connected, titles } = props;

  return (
    <Card.Content>
      <Statistic.Group widths="1" size="small">
        <THPChannel
          {...Temperature}
          {...titles[0]}
          color="red"
          Connected={Connected}
        ></THPChannel>
        <THPChannel
          {...RH}
          {...titles[1]}
          color="green"
          Connected={Connected}
        ></THPChannel>
        <THPChannel
          {...DP}
          {...titles[2]}
          color="blue"
          Connected={Connected}
        ></THPChannel>
      </Statistic.Group>
    </Card.Content>
  );
}
