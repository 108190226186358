import React from "react";
import {
  Card,
  Button,
  Icon,
  Modal,
  Header,
  Form,
  Message
} from "semantic-ui-react";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as mutation from "../../../GraphQL/mutations";
import { toast } from "react-toastify";
// import { round } from "../../Base/Helper";

export default class Actions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      PID: [],
      channels: [],
      settingsOpen: false
    };
  }

  handleSettingsOpen = () => {
    const { PID } = this.props.data;
    this.setState({
      settingsOpen: true,
      PID: PID
    });
  };

  handleSettingsClose = () => {
    this.setState({ settingsOpen: false });
  };

  handleConfigOpen = () => {
    const { title, subtitle, order, channels } = this.props;
    this.setState({
      configOpen: true,
      title: title,
      subtitle: subtitle,
      order: order,
      channels: channels
    });
  };

  handleConfigClose = () => this.setState({ configOpen: false });

  handleSetPointChange = (event, data) => {
    if (
      (!Number.isNaN(data.value) &&
        data.value >= data.minimum &&
        data.value <= data.maximum) ||
      data.value === ""
    ) {
      this.setState(state => {
        const PID = state.PID.map((item, index) => {
          const newItem = { ...item };
          if (data.index === index) {
            newItem.SetPoint = data.value;
            newItem.error = newItem.SetPoint ? null : "Set Point Required";
          }
          return newItem;
        });
        return { PID };
      });
    }
  };

  handleAlarmChange = (event, data) => {
    if (
      (!Number.isNaN(data.value) &&
        data.value >= data.minimum &&
        data.value <= data.maximum) ||
      data.value === ""
    ) {
      this.setState(state => {
        const PID = state.PID.map((item, index) => {
          const newItem = { ...item };
          if (data.index === index) {
            if (data.name === "1") {
              newItem.Alarm[0].Set = data.value;
              newItem.Alarm[0].error = newItem.Alarm[0].Set
                ? null
                : "Alarm 1 Required";
            } else {
              newItem.Alarm[1].Set = data.value;
              newItem.Alarm[1].error = newItem.Alarm[1].Set
                ? null
                : "Alarm 2 Required";
            }
          }
          return newItem;
        });
        return { PID };
      });
    }
  };

  handleCAChange = (event, data) => {
    this.setState(state => {
      const PID = state.PID.map((item, index) => {
        const newItem = { ...item };
        if (data.index === index) {
          newItem.ControlAction = data.checked;
        }
        return newItem;
      });
      return { PID };
    });
  };

  handleChannelChange = (event, data) => {
    this.setState(state => {
      const channels = state.channels.map((item, index) => {
        const newItem = { ...item };
        if (data.index === index) {
          newItem[data.name] = data.value;
        }
        return newItem;
      });
      return { channels };
    });
  };

  onConfigChange = (event, data) => {
    if (data.value.length < 20) {
      this.setState({ [data.name]: data.value });
    }
  };

  onOrderChange = (event, data) => {
    if (
      (Number.isInteger(Number(data.value)) &&
        data.value > 0 &&
        data.value <= 50) ||
      data.value === ""
    ) {
      this.setState({ [data.name]: data.value });
    }
  };

  SuccessMessage = message => ({ closeToast }) => (
    <Message
      success
      icon="check"
      header="Success"
      content={message}
      onDismiss={closeToast}
    />
  );

  ErrorMessage = message => ({ closeToast }) => (
    <Message
      error
      icon="warning circle"
      header="Error"
      content={message}
      onDismiss={closeToast}
    />
  );

  render() {
    const { pk, sk, onUpdate } = this.props;
    // const propChannel = this.props.channels;
    const {
      // PID,
      // settingsOpen,
      configOpen,
      title,
      subtitle,
      order,
      channels
    } = this.state;

    // const SettingsUpdateDisable = PID.reduce((acc, cur) => {
    //   return acc || !(cur.SetPoint && cur.Alarm[0].Set && cur.Alarm[1].Set);
    // }, false);

    // const warning = PID.reduce((acc, cur) => {
    //   return acc || cur.warning;
    // }, false);

    const configUpdateDisabled = !(
      title &&
      subtitle &&
      order &&
      channels.reduce((acc, cur) => {
        return acc && cur.name;
      }, true)
    );

    return (
      <Card.Content extra>
        {/*<Modal
          open={settingsOpen}
          onClose={this.handleSettingsClose}
          size="small"
          trigger={
            <Button basic compact onClick={this.handleSettingsOpen}>
              <Icon name="setting" />
              Settings
            </Button>
          }
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Header as="h4">
            <Icon name="settings" />
            <Header.Content>
              Settings
              <Header.Subheader>{sk}</Header.Subheader>
            </Header.Content>
          </Header>
          <Modal.Content>
            <Form warning={warning}>
              {PID.map((item, index) => {
                const disabled = !item.Connected;
                return (
                  <React.Fragment key={index}>
                    <Header as="h5">
                      {propChannel[index].name +
                        " (" +
                        propChannel[index].unit +
                        ")"}
                      <Header.Subheader>
                        Slave ID: {item.slaveID}
                      </Header.Subheader>
                    </Header>
                    <Form.Group widths="3">
                      <Form.Input
                        label="Set Point"
                        value={item.SetPoint}
                        disabled={disabled}
                        index={index}
                        minimum={item.SPNL || 1}
                        maximum={item.SPNH}
                        onChange={this.handleSetPointChange}
                        error={item.error}
                      />
                      <Form.Input
                        label={"Alarm 1 (" + item.Alarm[0].Type + ")"}
                        value={item.Alarm[0].Set}
                        disabled={disabled}
                        index={index}
                        minimum={item.SPNL || 1}
                        maximum={item.SPNH}
                        onChange={this.handleAlarmChange}
                        name="1"
                        error={item.Alarm[0].error}
                      />
                      <Form.Input
                        label={"Alarm 2 (" + item.Alarm[1].Type + ")"}
                        value={item.Alarm[1].Set}
                        disabled={disabled}
                        index={index}
                        minimum={item.SPNL || 1}
                        maximum={item.SPNH}
                        onChange={this.handleAlarmChange}
                        name="2"
                        error={item.Alarm[1].error}
                      />
                    </Form.Group>
                    <Form.Checkbox
                      toggle
                      label="Control Action"
                      checked={item.ControlAction}
                      index={index}
                      disabled={disabled}
                      onChange={this.handleCAChange}
                    />
                    {item.warning ? (
                      <Message
                        warning
                        header="Invalid Alarm Settings"
                        content="Alarm High cannot be less than Alarm Low"
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
              <Connect mutation={graphqlOperation(mutation.UpdateShadow)}>
                {({ mutation }) => (
                  <Button
                    positive
                    disabled={SettingsUpdateDisable}
                    onClick={async () => {
                      const UserConfig = {
                        PID: PID.map(item => {
                          return {
                            Alarm: [
                              { Set: round(item.Alarm[0].Set) },
                              { Set: round(item.Alarm[1].Set) }
                            ],
                            slaveID: item.slaveID,
                            SetPoint: round(item.SetPoint),
                            ControlAction: item.ControlAction
                          };
                        })
                      };
                      const response = await mutation({
                        thing: sk,
                        groupID: pk,
                        UserConfig: UserConfig
                      });
                      if (response.data && response.data.updateShadow) {
                        toast(this.SuccessMessage("Settings Updated"));
                      } else {
                        toast(this.ErrorMessage("Update Failed"));
                      }
                      this.handleSettingsClose();
                    }}
                  >
                    Update
                  </Button>
                )}
              </Connect>
            </Form>
          </Modal.Content>
                  </Modal>*/}
        <Modal
          open={configOpen}
          onClose={this.handleConfigClose}
          size="mini"
          trigger={
            <Button
              floated="right"
              basic
              compact
              onClick={this.handleConfigOpen}
            >
              <Icon name="configure" />
              Configure
            </Button>
          }
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Header as="h4">
            <Icon name="configure" />
            <Header.Content>
              Configure
              <Header.Subheader>{sk}</Header.Subheader>
            </Header.Content>
          </Header>
          <Modal.Content>
            <Form>
              <Form.Input
                label="Title"
                value={title}
                name="title"
                onChange={this.onConfigChange}
              />
              <Form.Input
                label="Subtitle"
                value={subtitle}
                name="subtitle"
                onChange={this.onConfigChange}
              />
              <Form.Input
                label="Order"
                value={order}
                name="order"
                onChange={this.onOrderChange}
              />
              <Header>Channel Headers</Header>
              {channels.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Form.Group widths={2} unstackable>
                      <Form.Input
                        label="Name"
                        value={item.name}
                        index={index}
                        name="name"
                        onChange={this.handleChannelChange}
                      />
                      <Form.Input
                        label="Unit"
                        value={item.unit}
                        index={index}
                        name="unit"
                        onChange={this.handleChannelChange}
                      />
                    </Form.Group>
                  </React.Fragment>
                );
              })}
              <Connect mutation={graphqlOperation(mutation.UpdateDevice)}>
                {({ mutation }) => (
                  <Button
                    disabled={configUpdateDisabled}
                    positive
                    onClick={() => {
                      const input = {
                        name: sk,
                        title: title,
                        subtitle: subtitle,
                        order: Number(order),
                        channels: channels
                      };
                      mutation({
                        input: input,
                        groupID: pk
                      })
                        .then(response => {
                          console.log(response);
                          const {
                            name,
                            title,
                            subtitle,
                            order,
                            channels
                          } = response.data.updateDevice;
                          onUpdate(name, title, subtitle, order, channels);
                          toast(this.SuccessMessage("Config Updated"));
                          this.handleConfigClose();
                        })
                        .catch(err => {
                          console.error(err);
                          toast(this.ErrorMessage("Update Failed"));
                          this.handleConfigClose();
                        });
                    }}
                  >
                    Update
                  </Button>
                )}
              </Connect>
            </Form>
          </Modal.Content>
        </Modal>
      </Card.Content>
    );
  }
}
