export const addUser = `mutation AddUser($groupID: String, $username: String, $role: Int) {
    addUser(groupID: $groupID ,username: $username,role: $role){
      pk
      sk
      role
    }
  }
  `;

export const delUser = `mutation DelUser($groupID: String, $username: String) {
    deleteUser(groupID:$groupID, username:$username){
      pk
      sk
      role
    }
  }
  `;

export const addDevice = `mutation AddDevice($input: Thing, $secret: String, $groupID: String) {
    addDevice(input: $input, groupID: $groupID, secret: $secret) {
      pk
      sk
      createdOn
      createdBy
      guiID
      model
      name
      order
      subtitle
      title
      channels {
        name
        unit
      }
    }
  }
  `;

export const delDevice = `mutation DeleteDevice($thing: String, $groupID: String) {
  deleteDevice(thing:$thing,groupID:$groupID) {
      pk
      sk
    }
  }`;

export const addGroup = `mutation addGroup($name: String){
  addGroup(name: $name) {
    pk
    sk
  } 
}`;

export const delGroup = `mutation deleteGroup($groupID: String) {
  deleteGroup(groupID: $groupID) {
    pk
    sk
  }
}`;

export const UpdateShadow = `mutation updateShadow($thing: String, $groupID: String, $UserConfig: UserConfig){
  updateShadow(thing:$thing, groupID: $groupID, UserConfig: $UserConfig)
}`;

export const UpdateDevice = `mutation updateDevice($input: Thing, $groupID: String) 
{
  updateDevice(input: $input, groupID: $groupID) {
    pk
    sk
    name
      order
      subtitle
      title
      channels {
        name
        unit
      }
  }
}
`;

export const UpdateGroup = `mutation updateGroup($groupID: String!, $name: String!)
{
  updateGroup(groupID: $groupID, name: $name) {
    pk
    sk
    name
  }
}`;
