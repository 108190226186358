import React, { Component } from "react";
import { Card } from "semantic-ui-react";
import CardHeaders from "../CardHeaders";

import NotifBar from "./NotifBar";
import Content from "./Content";
import { ExtraDown, ExtraUP } from "./Extra";
import Actions from "./Actions";

// import Actions from "./Actions";
export default class HTI3000 extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		// const { card } = this.props;
		// API.graphql(
		//   graphqlOperation(queries.getShadowDualAI, {
		//     thing: card.sk,
		//     groupID: card.pk
		//   })
		// ).then(({ data }) => {
		//   this.setState({
		//     ...data.getDeviceShadow,
		//     isLoaded: true
		//   });
		// });
	}

	render() {
		const { card, shadow, role, onUpdate } = this.props;
		console.log(this.props);
		return (
			<React.Fragment>
				<Card raised style={{ minWidth: 320, minHeight: 320 }}>
					<NotifBar {...shadow.data} />
					<CardHeaders {...card} updatedOn={new Date(shadow.Timestamp)} />
					<React.Fragment>
						<ExtraUP {...shadow.data} titles={card.channels} />
						<Content {...shadow.data} titles={card.channels} />
						<ExtraDown {...shadow.data} titles={card.channels} />
						{role >= 75 ? (
							<Actions {...shadow} {...card} onUpdate={onUpdate} />
						) : null}
					</React.Fragment>
				</Card>
			</React.Fragment>
		);
	}
}
