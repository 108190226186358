import React from "react";
import { Table, Icon } from "semantic-ui-react";

export default function ReportTable(props) {
  const { device, data } = props;
  console.log(data);
  return (
    <React.Fragment>
      <Table unstackable compact celled textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="2">Timestamp</Table.HeaderCell>
            {device.channels.map((item, index) => {
              return (
                <Table.HeaderCell key={index} colSpan="2">
                  {item.name + " (" + item.unit + ")"}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
          <Table.Row>
            {device.channels.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Table.HeaderCell>PV</Table.HeaderCell>
                  <Table.HeaderCell>SV</Table.HeaderCell>
                </React.Fragment>
              );
            })}
          </Table.Row>
        </Table.Header>
        {
          <Table.Body
            children={data.map(item => (
              <Table.Row key={item.sk}>
                <Table.Cell>{item.sk}</Table.Cell>
                {item.payload.data.PID.map((PID, index) => {
                  let alarmHigh = false;
                  let alarmLow = false;

                  PID.Alarm.map(alarm => {
                    if (alarm.Type === "HIGH" && alarm.Status === true) {
                      return (alarmHigh = true);
                    }
                    if (alarm.Type === "LOW" && alarm.Status === true) {
                      return (alarmLow = true);
                    }
                    return null;
                  });

                  return (
                    <React.Fragment key={index}>
                      <Table.Cell
                        positive={alarmLow}
                        negative={alarmHigh}
                        warning={!PID.Connected}
                      >
                        {PID.Connected ? null : <Icon name="attention" />}
                        {PID.Connected ? PID.Value : "N/C"}
                      </Table.Cell>
                      <Table.Cell warning={!PID.Connected}>
                        {PID.Connected ? null : <Icon name="attention" />}
                        {PID.Connected ? PID.SetPoint : "N/C"}
                      </Table.Cell>
                    </React.Fragment>
                  );
                })}
              </Table.Row>
            ))}
          />
        }
      </Table>
    </React.Fragment>
  );
}
