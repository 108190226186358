import React from "react";
import { Card } from "semantic-ui-react";
import ChannelGroup from "./ChannelGroup";

export default function DualAIStats(props) {
  const {
    Channel1,
    Channel2,
    Channel3,
    Channel4,
    Channel5,
    Channel6,
    Channel7,
    Channel8,
    Connected,
    titles
  } = props;

  Channel1.color = Channel1.AlarmHigh
    ? "red"
    : Channel1.AlarmLow
    ? "blue"
    : "black";

  Channel2.color = Channel2.AlarmHigh
    ? "red"
    : Channel2.AlarmLow
    ? "blue"
    : "black";

  Channel3.color = Channel3.AlarmHigh
    ? "red"
    : Channel3.AlarmLow
    ? "blue"
    : "black";

  Channel4.color = Channel4.AlarmHigh
    ? "red"
    : Channel4.AlarmLow
    ? "blue"
    : "black";

  Channel5.color = Channel5.AlarmHigh
    ? "red"
    : Channel5.AlarmLow
    ? "blue"
    : "black";

  Channel6.color = Channel6.AlarmHigh
    ? "red"
    : Channel6.AlarmLow
    ? "blue"
    : "black";

  Channel7.color = Channel7.AlarmHigh
    ? "red"
    : Channel7.AlarmLow
    ? "blue"
    : "black";

  Channel8.color = Channel8.AlarmHigh
    ? "red"
    : Channel8.AlarmLow
    ? "blue"
    : "black";

  return (
    <Card.Content>
      <ChannelGroup
        Channel1={Channel1}
        title1={titles[0]}
        Channel2={Channel2}
        title2={titles[1]}
        Connected={Connected}
      />
      <ChannelGroup
        Channel1={Channel3}
        title1={titles[2]}
        Channel2={Channel4}
        title2={titles[3]}
        Connected={Connected}
      />
      <ChannelGroup
        Channel1={Channel5}
        title1={titles[4]}
        Channel2={Channel6}
        title2={titles[5]}
        Connected={Connected}
      />
      <ChannelGroup
        Channel1={Channel7}
        title1={titles[6]}
        Channel2={Channel8}
        title2={titles[7]}
        Connected={Connected}
      />
    </Card.Content>
  );
}
