import React, { Component } from "react";
import ReportTable from "./ReportTable";
import ReportGraph from "./ReportGraph";

export default class JLCSAFReport extends Component {
  render() {
    const { showGraph, showTable } = this.props;
   // console.log({ ...this.props });
    return (
      <React.Fragment>
        {showGraph ? <ReportGraph {...this.props} /> : null}
        {showTable ? <ReportTable {...this.props} /> : null}
      </React.Fragment>
    );
  }
}
