import React, { Component } from "react";
import { Card, Statistic } from "semantic-ui-react";
import CardHeaders from "../CardHeaders";
import ContentPID from "../gui-JLCGEM/content-PID";
import ContentEnergyMeter from "./content-EnergyMeter";
import Actions from "./Actions";

export default class JLCSAFCard extends Component {
  render() {
    const { card, shadow, role, onUpdate } = this.props;
    console.log("Reloaded");
    return (
      <React.Fragment>
        <Card raised style={{ minWidth: 320, minHeight: 100 }}>
          <CardHeaders {...card} updatedOn={new Date(shadow.Timestamp)} />
          <Card.Content>
            <Statistic.Group size="small" widths="2">
              {shadow.data.PID.map((item, index) => (
                <ContentPID
                  key={index}
                  {...item}
                  titles={card.channels[index]}
                />
              ))}
            </Statistic.Group>
          </Card.Content>
          <Card.Content>
            <ContentEnergyMeter {...shadow.data.EnergyMeter[0]} />
          </Card.Content>
          {role >= 75 ? (
            <Actions {...shadow} {...card} onUpdate={onUpdate} />
          ) : null}
        </Card>
      </React.Fragment>
    );
  }
}
