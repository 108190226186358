import React from "react";
import { Loader, Dimmer, Header, Icon } from "semantic-ui-react";

export default function MyLoader(props) {
  const { error, isLoaded } = props;

  return (
    <React.Fragment>
      <Dimmer active={!isLoaded || error}>
        {error ? (
          <Header icon as="h3" inverted>
            <Icon name="warning sign" color="red" />
            {error}
          </Header>
        ) : !isLoaded ? (
          <Loader>Loading</Loader>
        ) : null}
      </Dimmer>
    </React.Fragment>
  );
}
