import React from "react";
import { Card } from "semantic-ui-react";

export default function Extra(props) {
  const { RH, Output, titles } = props;
  return (
    <Card.Content extra>
      <span style={{ float: "left" }}>
        Set Point: {RH.SetPoint + " " + titles[0].unit}
      </span>
      <span style={{ float: "right" }}>Output: {Output} %</span>
    </Card.Content>
  );
}
