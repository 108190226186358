import React from "react";
import { Card } from "semantic-ui-react";

const relayMap = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Extra = (props) => {
	return (
		<Card.Content>
			<Card.Group centered itemsPerRow="4">
				{relayMap.map((item) => (
					<Card color={props.Relay[`Rly${item}`] === "ON" ? "green" : "red"}>
						<Card.Content textAlign="center">
							<b>Stage {item}</b>
							<br></br>
							{props.Relay[`Rly${item}`]}
						</Card.Content>
					</Card>
				))}
			</Card.Group>
		</Card.Content>
	);
};

export default Extra;
