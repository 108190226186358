import React, { Component } from "react";
import DualAIReport from "./gui-DualAI/Report";
import JLCSAFReport from "./gui-JLCSAF/Report";
import Manvi01Report from "./gui-Manvi01/Report";
import Manvi02Report from "./gui-Manvi02/Report";
import MultiPIDReport from "./gui-MultiPID/Report";
import MultiChannelReport from "./gui-MultiChannel/Report";
import THPReport from "./gui-THP/Report";
import JLCGEMReport from "./gui-JLCGEM/Report";
import HTIReport from "./gui-HTI3000/Report";
import { Segment, Grid, Header, Image } from "semantic-ui-react";
import Clariant01Report from "./gui-Clariant01/Report";

export default class LoadedReport extends Component {
	render() {
		const { device, startDate, endDate } = this.props;
		var reportToRender;
		switch (device.guiID) {
			case "DualAI":
				reportToRender = <DualAIReport {...this.props} />;
				break;
			case "JLCSAF":
				reportToRender = <JLCSAFReport {...this.props} />;
				break;
			case "Manvi01":
				reportToRender = <Manvi01Report {...this.props} />;
				break;
			case "Manvi02":
				reportToRender = <Manvi02Report {...this.props} />;
				break;
			case "MultiPID":
				reportToRender = <MultiPIDReport {...this.props} />;
				break;
			case "MultiChannel":
				reportToRender = <MultiChannelReport {...this.props} />;
				break;
			case "THP":
				reportToRender = <THPReport {...this.props} />;
				break;
			case "JLCGEM":
				reportToRender = <JLCGEMReport {...this.props} />;
				break;
			case "HTI3000":
				reportToRender = <HTIReport {...this.props}></HTIReport>;
				break;
			case "Clariant01":
				reportToRender = <Clariant01Report {...this.props}></Clariant01Report>;
				break;
			default:
				reportToRender = null;
				break;
		}
		// console.log(reportToRender);

		return (
			<div id="section-to-print">
				<table style={{ width: "100%" }}>
					<thead>
						<tr>
							<th>
								<Segment>
									<Grid columns="equal" verticalAlign="middle">
										<Grid.Row>
											<Grid.Column textAlign="left">
												{device.guiID === "Clariant01" ? (
													<Image
														size="small"
														src={"./clariant-logo.jpg"}
													></Image>
												) : null}
												Serial No: {device.sk}
											</Grid.Column>
											<Grid.Column textAlign="center">
												<Header
													content={device.title}
													subheader={device.subtitle}
												/>
											</Grid.Column>
											<Grid.Column textAlign="right">
												Start Date/Time: {startDate}
												<br />
												End Date/Time: {endDate}
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Segment>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{reportToRender}</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}
