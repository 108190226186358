import * as queries from "../../GraphQL/queries";

export const selectShadowType = (guiID) => {
	switch (guiID) {
		case "DualAI":
			return queries.getShadowDualAI;
		case "JLCGEM":
			return queries.getJLCGEMShadow;
		case "JLCSAF":
			return queries.getJLCSAFShadow;
		case "Manvi01":
			return queries.getManvi01Shadow;
		case "Manvi02":
			return queries.getManvi02Shadow;
		case "MultiPID":
			return queries.getMultiPIDShadow;
		case "MultiChannel":
			return queries.getMultiChannelShadow;
		case "THP":
			return queries.getTHPShadow;
		case "HTI3000":
			return queries.getHTI3000Shadow;
		case "Clariant01":
			return queries.getClariant01Shadow;
		default:
			console.log("Invalid Format");
			return null;
	}
};

export const selectDataPointType = (guiID) => {
	switch (guiID) {
		case "DualAI":
			return queries.getDualAIData;
		case "JLCSAF":
			return queries.getJLCSAFData;
		case "Manvi01":
			return queries.getManvi01Data;
		case "Manvi02":
			return queries.getManvi02Data;
		case "MultiPID":
			return queries.MultiPIDData;
		case "MultiChannel":
			return queries.MultiChannelData;
		case "THP":
			return queries.THPData;
		case "JLCGEM":
			return queries.JLCGEMData;
		case "HTI3000":
			return queries.getHTI3000Data;
		case "Clariant01":
			return queries.getClariant01Data;
		default:
			console.log("Invalid Format");
			return null;
	}
};

export const round = (value, precision) => {
	var multiplier = Math.pow(10, precision || 0);
	return Math.round(value * multiplier) / multiplier;
};
