import React, { Component } from "react";
import CardDeck from "../Cards/CardDeck";
import { Header, Container, Icon, Message } from "semantic-ui-react";
import NewDevice from "../Devices/NewDevice";
import RoleLevelLabel from "../Base/RoleLevelLabel";
import UsersPage from "../Users/UsersPage";
import SelectedGroupMenu from "./SelectedGroupMenu";

import { API, graphqlOperation } from "aws-amplify";
// import * as queries from "../../GraphQL/queries";
import * as mutations from "../../GraphQL/mutations";
import MyLoader from "../Base/MyLoader";

import PubSub from "@aws-amplify/pubsub";
import { selectShadowType } from "../Base/Helper";

import { toast } from "react-toastify";
import DataScreen from "../Data/DataScreen";
import GroupSettings from "./GroupSettings";

export default class SelectedGroup extends Component {
	constructor(props) {
		super(props);

		this.addDevice = this.addDevice.bind(this);

		this.state = {
			currentState: "Devices",
			devices: {},
			awaitAdd: false,
		};
		this.sub = [];
		this.awaitAddLoad = null;
	}

	componentDidMount() {
		const { items } = this.props;
		//console.log(items);
		this.setState({ loadWait: items.length });
		items.map((item) => this.addDeviceToList(item));

		//Keep Alive Checker
		this.KASub = PubSub.subscribe(
			"KeepAlive/" + PubSub._pluggables[0]._config.clientId
		).subscribe({
			next: (data) => {
				console.log(data);
				clearTimeout(this.kill);
			},
			error: (error) => console.error("MyError: ", error),
			close: () => console.log("KA Done"),
		});

		//Keep Alive Publisher
		this.pub = setInterval(() => {
			PubSub.publish("KeepAlive/" + PubSub._pluggables[0]._config.clientId, {
				clientID: PubSub._pluggables[0]._config.clientId,
			});
			this.kill = setTimeout(() => {
				this.setState({ mqtt_fail: true });
				// this.sub.unsubscribe();
				clearInterval(this.pub);
			}, 2000);
		}, 60000);
	}

	componentWillUnmount() {
		this.KASub.unsubscribe();
		this.sub.map((sub) => {
			return sub.unsubscribe();
		});
		clearTimeout(this.kill);
		clearInterval(this.pub);
		clearTimeout(this.awaitAddLoad);
	}

	addDeviceToList(item) {
		const shadowFormat = selectShadowType(item.guiID);
		if (shadowFormat) {
			this.setState({ devices: { [item.sk]: null, ...this.state.devices } });
			return API.graphql(
				graphqlOperation(shadowFormat, {
					thing: item.sk,
					groupID: item.pk,
				})
			)
				.then(({ data }) => {
					console.log("Shadow:", data.getDeviceShadow);
					const device = {
						device: item,
						shadow: data.getDeviceShadow,
					};
					this.setState({
						devices: { ...this.state.devices, [device.device.sk]: device },
						loadWait: this.state.loadWait - 1,
					});
					const { Model, ThingName, OwnerID } = device.shadow;
					// console.log(Model + "/" + OwnerID + "/" + ThingName + "/Status");
					const newSub = PubSub.subscribe(
						Model + "/" + OwnerID + "/" + ThingName + "/Status"
					).subscribe({
						next: (data) => {
							// console.log(data);
							if (data.value && data.value.ThingName) {
								this.setState({
									devices: {
										...this.state.devices,
										[data.value.ThingName]: {
											...this.state.devices[data.value.ThingName],
											shadow: {
												...this.state.devices[data.value.ThingName].shadow,
												data: data.value.data,
												Timestamp: data.value.Timestamp,
											},
										},
									},
								});
							} else {
								console.error("Invalid JSON", data);
							}
						},
						error: (error) => console.error(error),
						close: () => console.log("Done"),
					});
					newSub.key = ThingName;
					this.sub = [...this.sub, newSub];
				})
				.catch(console.log);
		} else {
			console.log("Skipping Device");
			this.setState((state) => {
				return { loadWait: state.loadWait - 1 };
			});
		}
	}

	onMenuButtonClick = (event, data) => {
		this.setState({ currentState: data.name });
	};

	addDevice(input, secret, groupID) {
		this.setState({ awaitAdd: true });
		this.awaitAddLoad = setTimeout(() => {
			this.setState({ awaitAdd: false });
		}, 5000);

		API.graphql(
			graphqlOperation(mutations.addDevice, { input, secret, groupID })
		)
			.then((response) => {
				// console.log(response);
				this.setState({ loadWait: 1 });
				this.addDeviceToList(response.data.addDevice);
				toast(this.SuccessMessage);
				this.setState({ currentState: "Devices" });
			})
			.catch((error) => {
				console.error(error);
				if (error.errors[0].errorType === "Unauthorized") {
					toast(this.ErrorMessage("Invalid Secret"));
				} else {
					toast(this.ErrorMessage("Failed to add device"));
				}
			});
	}

	removeDevice = (thingName) => {
		this.setState((state) => {
			var newState = state;
			delete newState.devices[thingName];
			return newState;
		});

		const removeIndex = this.sub.findIndex((item) => {
			return item.key === thingName;
		});
		this.sub.splice(removeIndex, 1);
	};

	SuccessMessage = ({ closeToast }) => (
		<Message
			success
			icon="check"
			header="Success"
			content="New Device Added"
			onDismiss={closeToast}
		/>
	);

	ErrorMessage = (message) => ({ closeToast }) => (
		<Message
			error
			icon="warning circle"
			header="Error"
			content={message}
			onDismiss={closeToast}
		/>
	);

	updateDevice = (device, title, subtitle, order, channels) => {
		this.setState({
			devices: {
				...this.state.devices,
				[device]: {
					...this.state.devices[device],
					device: {
						...this.state.devices[device].device,
						title: title,
						subtitle: subtitle,
						order: order,
						channels: channels,
					},
				},
			},
		});
	};

	updateGroup = () => {
		this.props.onBackClick();
	};

	render() {
		const { currentState, devices, loadWait, awaitAdd, mqtt_fail } = this.state;
		const { name, sk, role } = this.props.group;
		const { theme_dark } = this.props;
		var PageContent;

		switch (currentState) {
			case "Devices":
				PageContent = (
					<CardDeck items={devices} role={role} onUpdate={this.updateDevice} />
				);
				break;
			case "Data":
				PageContent = <DataScreen items={devices} />;
				break;
			case "Users":
				PageContent = <UsersPage groupID={sk} role={role} />;
				break;
			case "Group Settings":
				PageContent = (
					<GroupSettings
						{...this.props.group}
						onUpdate={this.updateGroup}
						items={devices}
						role={role}
						removeDevice={this.removeDevice}
					/>
				);
				break;
			case "Add Device":
				PageContent = (
					<NewDevice
						addDevice={this.addDevice}
						groupID={sk}
						awaitAdd={awaitAdd}
					/>
				);
				break;
			default:
				break;
		}
		return (
			<React.Fragment>
				<Container>
					<Header as="h2">
						<Header.Content>
							<Icon name="cubes" />
							{name}
						</Header.Content>
						<RoleLevelLabel {...this.props.group} />
					</Header>
					<SelectedGroupMenu
						onMenuButtonClick={this.onMenuButtonClick}
						onBackClick={this.props.onBackClick}
						currentState={currentState}
						role={role}
						theme_dark={theme_dark}
					/>
					<br />
					{mqtt_fail ? (
						<Message
							error
							icon="warning sign"
							header="Connection Failure"
							content="Check internet connection and reload"
							floating
						/>
					) : null}
					{loadWait === 0 ? PageContent : <MyLoader />}
				</Container>
			</React.Fragment>
		);
	}
}
