import React from "react";
import {
	Card,
	Button,
	Icon,
	Modal,
	Form,
	Message,
	Header
} from "semantic-ui-react";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as mutation from "../../../GraphQL/mutations";
import { toast } from "react-toastify";
import { round } from "../../Base/Helper";

import PubSub from "@aws-amplify/pubsub";

export default class Actions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			settingsOpen: false
		};
	}

	onNameChange = (event, data) => {
		if (!Number.isNaN(data.value) && data.value >= 0 && data.value <= 100) {
			this.setState({ [data.name]: data.value });
		}
	};

	onConfigChange = (event, data) => {
		if (data.value.length < 20) {
			this.setState({ [data.name]: data.value });
		}
	};

	onOrderChange = (event, data) => {
		if (
			(Number.isInteger(Number(data.value)) &&
				data.value > 0 &&
				data.value <= 50) ||
			data.value === ""
		) {
			this.setState({ [data.name]: data.value });
		}
	};

	handleSettingsOpen = () => {
		const { SetPoint, AlarmHighSP, AlarmLowSP } = this.props.data.RH;

		const { ControlAction } = this.props.data;
		this.setState({
			settingsOpen: true,
			SetPoint: SetPoint,
			AlarmHighSP: AlarmHighSP,
			AlarmLowSP: AlarmLowSP,
			ControlAction: ControlAction
		});
	};

	handleSettingsClose = () => this.setState({ settingsOpen: false });

	handleConfigOpen = () => {
		const { title, subtitle, order } = this.props;
		this.setState({
			configOpen: true,
			title: title,
			subtitle: subtitle,
			order: order
		});
	};

	handleConfigClose = () => this.setState({ configOpen: false });

	SuccessMessage = message => ({ closeToast }) => (
		<Message
			success
			icon="check"
			header="Success"
			content={message}
			onDismiss={closeToast}
		/>
	);

	ErrorMessage = message => ({ closeToast }) => (
		<Message
			error
			icon="warning circle"
			header="Error"
			content={message}
			onDismiss={closeToast}
		/>
	);

	render() {
		const {
			AlarmHighSP,
			AlarmLowSP,
			SetPoint,
			ControlAction,
			settingsOpen,
			configOpen,
			title,
			subtitle,
			order
		} = this.state;
		const { pk, sk, channels, onUpdate } = this.props;

		const settingsUpdateDisabled = !(AlarmHighSP && AlarmLowSP && SetPoint);

		const configUpdateDisabled = !(title && order && subtitle);

		return (
			<Card.Content extra>
				{
					<Modal
						open={settingsOpen}
						onClose={this.handleSettingsClose}
						size="mini"
						trigger={
							<Button basic compact onClick={this.handleSettingsOpen}>
								<Icon name="setting" />
								Settings
							</Button>
						}
						closeIcon
						closeOnDimmerClick={false}
						closeOnEscape={false}
					>
						<Header as="h4">
							<Icon name="settings" />
							<Header.Content>
								Settings
								<Header.Subheader>{sk}</Header.Subheader>
							</Header.Content>
						</Header>
						<Modal.Content>
							<Form>
								<Form.Input
									label="Set Point"
									value={SetPoint}
									name="SetPoint"
									onChange={this.onNameChange}
								/>
								<Form.Input
									label="Alarm High Set Point"
									value={AlarmHighSP}
									name="AlarmHighSP"
									onChange={this.onNameChange}
								/>
								<Form.Input
									label="Alarm Low Set Point"
									value={AlarmLowSP}
									name="AlarmLowSP"
									onChange={this.onNameChange}
								/>
								<Form.Checkbox
									toggle
									label="Control Action"
									checked={ControlAction}
									onChange={() =>
										this.setState({
											ControlAction: !this.state.ControlAction
										})
									}
								/>
								{/*	<Connect mutation={graphqlOperation(mutation.UpdateShadow)}>
                {({ mutation }) => (*/}
								<Button
									positive
									disabled={settingsUpdateDisabled}
									onClick={async () => {
										const { Model, ThingName, OwnerID } = this.props;
										const UserConfig = {
											RH: {
												SetPoint: round(SetPoint, 1),
												AlarmHighSP: round(AlarmHighSP, 1),
												AlarmLowSP: round(AlarmLowSP, 1)
											},
											ControlAction: ControlAction
										};
										PubSub.publish(
											Model + "/" + OwnerID + "/" + ThingName + "/Update",
											{ UserConfig }
										);
										// const response = await mutation({
										// 	thing: sk,
										// 	groupID: pk,
										// 	UserConfig: UserConfig
										// });
										// if (response.data && response.data.updateShadow) {
										// } else {
										// 	toast(this.ErrorMessage("Update Failed"));
										// }
										toast(this.SuccessMessage("Updated Requested"));
										this.handleSettingsClose();
									}}
								>
									Update
								</Button>
								{
									// </Connect>
								}
							</Form>
						</Modal.Content>
					</Modal>
				}
				<Modal
					open={configOpen}
					onClose={this.handleConfigClose}
					size="mini"
					trigger={
						<Button
							floated="right"
							basic
							compact
							onClick={this.handleConfigOpen}
						>
							<Icon name="configure" />
							Configure
						</Button>
					}
					closeIcon
					closeOnDimmerClick={false}
					closeOnEscape={false}
				>
					<Header as="h4">
						<Icon name="configure" />
						<Header.Content>
							Configure
							<Header.Subheader>{sk}</Header.Subheader>
						</Header.Content>
					</Header>
					<Modal.Content>
						<Form>
							<Form.Input
								label="Title"
								value={title}
								name="title"
								onChange={this.onConfigChange}
							/>
							<Form.Input
								label="Subtitle"
								value={subtitle}
								name="subtitle"
								onChange={this.onConfigChange}
							/>
							<Form.Input
								label="Order"
								value={order}
								name="order"
								onChange={this.onOrderChange}
							/>
							<Connect mutation={graphqlOperation(mutation.UpdateDevice)}>
								{({ mutation }) => (
									<Button
										disabled={configUpdateDisabled}
										positive
										onClick={() => {
											const input = {
												name: sk,
												title: title,
												subtitle: subtitle,
												order: Number(order),
												channels: channels
											};
											mutation({
												input: input,
												groupID: pk
											})
												.then(response => {
													console.log(response);
													const {
														name,
														title,
														subtitle,
														order,
														channels
													} = response.data.updateDevice;
													onUpdate(name, title, subtitle, order, channels);
													toast(this.SuccessMessage("Config Updated"));
													this.handleConfigClose();
												})
												.catch(err => {
													console.error(err);
													toast(this.ErrorMessage("Update Failed"));
													this.handleConfigClose();
												});
										}}
									>
										Update
									</Button>
								)}
							</Connect>
						</Form>
					</Modal.Content>
				</Modal>
			</Card.Content>
		);
	}
}
